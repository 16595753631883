import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getTransactions } from '../../Services/Invoice.service'
import { buyerPurchaseDashboard } from '../../Services/Bidding.service'
import moment from 'moment-timezone'
import PaginationComponent from '../Pagination'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import { handleRedirectInternal } from '../../Product/common/components'
const AllTransactions = () => {
    const [page, setPage] = useState(1)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const history = useHistory()
    useEffect(() => {
        const getData = async () => {
            let { data } = await buyerPurchaseDashboard({
                sh_limit: 10,
                page,
                order: 1,
                auction_io: global?.storeDetails?.theme == 19 ? 1 : 0,
            }) //await getTransactions({ page, sh_limit: 10 });
            if (data.success === 'yes') {
                setData([...data.results])
                setTotal(data.total_pagecnt)
            }
        }
        getData()
    }, [page])

    const changePage = (number) => {
        document.getElementById('heading').scrollIntoView()
        setPage(parseInt(number))
    }

    return (
        <>
            <div className="table-responsive">
                <table className="table table-hover" id="table">
                    <thead>
                        <tr>
                            <th className="numCell">
                                {global?.storeDetails?.theme == 19 ? 'Invoice No' : '#'}
                            </th>
                            {global?.storeDetails?.theme == 19 && <th>Lot #</th>}
                            <th className="loa-item-name">
                                {global?.storeDetails?.theme == 19 ? 'Name' : 'Item Name'}
                            </th>
                            <th>Item Id</th>
                            <th>Amount</th>
                            {/* <th>Payment Mode</th> */}
                            <th>Purchased On</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className="common-invoice">{item.common_invoice}</td>
                                    {global?.storeDetails?.theme == 19 && (
                                        <td>{item.lot_number}</td>
                                    )}
                                    <td>{item.title}</td>
                                    <td>{item.id}</td>
                                    <td>US$ {parseFloat(item.buynowamount).toFixed(2)}</td>
                                    {/* <td>{item.pay_type}</td> */}
                                    <td>
                                        {moment(item.buynowpaid_date).isValid()
                                            ? moment(item.buynowpaid_date)
                                                  .tz('America/New_York')
                                                  .format('MMM Do YYYY')
                                            : ' - '}
                                        {global?.storeDetails?.theme !== 19 && 'ET'}
                                    </td>
                                    <td>{item.buynowpaid === 1 ? 'Paid' : 'unpaid'}</td>
                                    {/* {item.paid !== 1 ? (
                    <td>
                      <a className="ub-btn">Retry Payment</a>
                    </td>
                  ) : ( */}
                                    <td>
                                        <PrimaryButton
                                            label="View Invoice"
                                            className="mbv-btn"
                                            to={`/invoice/${item.common_invoice}`}
                                            onClick={() =>
                                                global?.storeDetails?.theme == 19
                                                    ? handleRedirectInternal(
                                                          history,
                                                          `invoice/auctions?id=${item.common_invoice}`,
                                                      )
                                                    : handleRedirectInternal(
                                                          history,
                                                          `invoice/${item.common_invoice}`,
                                                      )
                                            }
                                        />
                                    </td>
                                    {/* )} */}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            <PaginationComponent
                onPageChange={changePage}
                totalProductCount={parseInt(total)}
                currentPage={parseInt(page || 1)}
                resultToShow={10}
            />
        </>
    )
}

export default AllTransactions
