import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import Popup from '../../Product/components/organisms/Popup'
import { currencyFormat } from '../../Product/common/components'
const DateTime = (props) => {
    return (
        <Popup
            {...props}
            size="md"
            modalClass={`save-search-modal ${props.classNames}`}
            modaltitle="Date & Times"
        >
            <div className="date-time-container">
                <div className="dates-time-wrapper">
                    <h3 className="pop-dt-head">Add to Calendar:</h3>
                    <p>Google Yahoo Outlook iCal</p>
                </div>
                <div className="dates-time-wrapper">
                    <h3 className="pop-dt-head">Removal Terms:</h3>
                    <p>
                        Load Out Times: We are open for walkins Monday Through Saturday from 9:00 AM
                        to 6:00 PM.
                    </p>
                    <p>
                        Please schedule pickups two hours in advance so we can have your order ready
                        on time.
                    </p>
                    <p>
                        <strong>Location:</strong> 4801 West University Drive, Suite 104, Denton,
                        TX, 76207
                    </p>
                    <p>
                        <strong>Items must be picked up by 04/30/2024</strong>
                    </p>
                    <p>
                        <strong>Removal Assistance:</strong> Winning bidders will be responsible for
                        the pickup of items purchased and are required to bring their own help,
                        tools and transportation to remove items purchased.
                    </p>
                    <p>
                        <strong>Abandonment and Forfeit of items:</strong> If items is not picked up
                        within the started loadout time, the item will be considered abandoned and
                        forfeited. No refund will be given for abandoned, forfeited items.
                    </p>
                    <p>
                        <strong>Picking Up with Unpaid Invoice:</strong> All invoices must be paid
                        in full at all locations before any items will be released.
                    </p>
                    <p>Returns must be received by 04/30/2024 at 6:00 PM</p>
                </div>
                <div className="dates-time-wrapper">
                    <h3 className="pop-dt-head">Inspection</h3>
                    <p>
                        <strong>Extended Bidding:</strong> Ifa a bid is placed in the last 2
                        minutes(s) the auction will automatically extend for an additional 2
                        minute(s)
                    </p>
                    <p>
                        <strong>Location:</strong> 4801 West University Drive, Suite 104, Denton,
                        TX, 76207
                    </p>
                    <p>
                        <strong>Preview Times:</strong> No Preview for this Auction.
                    </p>
                    <p>
                        <strong>Buyer&apos;s Premium and Fees:</strong> There will be a 15% Buyers
                        Premium and a .92 cent lot fee assessed in this auction.
                    </p>
                    <p>Returns must be received by 04/30/2024</p>
                </div>
                <div className="dates-time-wrapper">
                    <h3 className="pop-dt-head">Bidding Starts:</h3>
                    <p>
                        {moment(props.auctionData.date_added)
                            .tz(moment.tz.guess())
                            .format('MMM Do YYYY, h:mm a z')}{' '}
                    </p>
                </div>
                <div className="dates-time-wrapper">
                    <h3 className="pop-dt-head">Bidding Ends:</h3>
                    <p>
                        {moment(props.auctionData.date_closed)
                            .tz(moment.tz.guess())
                            .format('MMM Do YYYY, h:mm a z')}
                    </p>
                    <p>See individual items for exact closing times.</p>
                </div>
            </div>
        </Popup>
    )
}

export default DateTime
