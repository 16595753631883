import React, { useContext, useEffect, useState } from 'react'
import './FilterPanel.css'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import RadioBox, { GreenRadio } from '../../atoms/RadioBox'
// import ProductContext from '../../../../product/context/product/productContext'
// import CustomCommonContext from '../../../../custom/context/common/commonContext'
// import ProductCommonContext from '../../../../product/context/common/commonContext'
import { useFormik } from 'formik'
import { Button } from '@material-ui/core'
import { Slider } from '@material-ui/core'
import { mapData } from '../../../common/components'
import CheckBox from '../../atoms/CheckBox'
import CommonContext from '../../../../Product/context/product/productContext'

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#fff',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 50,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails)

const FilterPanel = (props) => {
    const formik = props.formik
    const filterValues = props.filterValues

    const [expanded, setExpanded] = useState('panel0')

    const handleChange = (panel) => (event, newExpanded) => {
        if (panel === expanded && !newExpanded) {
            setExpanded(newExpanded ? panel : false)
        } else {
            setExpanded(newExpanded ? panel : false)
        }
    }

    return (
        <div className="filterPanel">
            {global.storeDetails?.theme !== 18 && (
                <div className="d-flex justify-content-between align-items-center filterTitleCnt">
                    <h4 className="fpTitle">Filters</h4>
                </div>
            )}
            <div>
                {props.clearSearch && (
                    <Button onClick={() => props.clearSearch()} className="filterClear">
                        <span className="material-icons">cancel</span>
                        Clear all
                    </Button>
                )}
            </div>

            {props.custom ? (
                <div className="row">{Object.values(mapData(props.filterValues))}</div>
            ) : (
                <div className="filterAcc">
                    {filterValues.map((data, index) => (
                        <>
                            {!data.hide ? (
                                props.allOpen ? (
                                    <Accordion
                                        square
                                        key={index}
                                        defaultExpanded={true}
                                        // expanded={expanded === `panel${index}`}
                                        onChange={handleChange(`panel${index}`)}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1d-content"
                                            id={`panel${index}d-header`}
                                        >
                                            <h6 className="accTitle">{data.title}</h6>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="filterCheck d-flex align-items-center flex-wrap">
                                                {mapData([data])}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                ) : (
                                    <Accordion
                                        square
                                        key={index}
                                        expanded={expanded === `panel${index}`}
                                        onChange={handleChange(`panel${index}`)}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1d-content"
                                            id={`panel${index}d-header`}
                                        >
                                            <h6 className="accTitle">{data.title}</h6>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="filterCheck d-flex align-items-center flex-wrap">
                                                {mapData([data])}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            ) : null}
                        </>
                    ))}
                    {props.filterData ? (
                        <Accordion
                            square
                            key={'pfil'}
                            expanded={expanded === `panel${'pfil'}`}
                            onChange={handleChange(`panel${'pfil'}`)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1d-content"
                                id={`panel${'pfil'}d-header`}
                            >
                                <h6 className="accTitle">{props.label}</h6>
                            </AccordionSummary>
                            <AccordionDetails>{mapData(props.filterData)}</AccordionDetails>
                        </Accordion>
                    ) : (
                        ''
                    )}
                </div>
            )}
        </div>
    )
}
export default FilterPanel
