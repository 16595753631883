import React, { useEffect, useState, useContext, useRef } from 'react'
import './searchItems.css'
import { getParameterByName } from '../../../utils/commonFunctions'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import SearchProductList from '../../../Component/ProductCard/product'
import GridProduct from '../../../Component/ProductCard/gridProduct'
import Layout from '../Layout'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FilterPanel from '../../../Product/components/organisms/FilterPanel'
import Loaders from '../../../Product/components/molecules/Loaders'
import { Button, Drawer, SwipeableDrawer } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import SlidePanel from '../../../Component/SlidePanel'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import { messageHandler } from '../../../Product/common/socketHandler'
import AlertContext from '../../../Product/context/alert/alertContext'
import PaginationComponent from '../../../Component/Pagination'
import { socket, socketAuction } from '../../../Product/common/socket'
import Slider from 'react-slick'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { handleRedirectInternal } from '../../../Product/common/components'
import { Link, useHistory, useLocation } from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import HeaderSearchComponent from '../Header/HeaderSearch'
import { sustainabilityOptions, brandsliqutatios, allCondition } from '../../../utils'
import SEO from '../../../utils/CommonFunctionality/SEO'
import { setAuthToken } from '../../../Product/common/api'
import CheckBox from '../../../Product/components/atoms/CheckBox'
const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const AuctionSearchComponent = (props) => {
    // const [cookies, setCookie] = useCookies(['user_session'])
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const history = useHistory()

    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const authContext = useContext(AuthContext)
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const { search_allproducts, getAllSearchProducts, productLocalState } = productContext
    const [auctionView, setAuctionView] = useState('Grid')

    const { isAuthenticated, user } = authContext
    const { setAlert } = alertContext

    const classes = useStyles()

    const [isLoading, setLoading] = useState(true)
    const [viewProduct, setViewProduct] = useState([])
    const [searchCategories, setSearchCategories] = useState([])
    const [states, setStates] = useState([])
    const [listview, setlistview] = useState(false)
    const [totalItems, setTotalItem] = useState(0)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)
    const location = useLocation()
    const forMobile = useMediaQuery('(max-width:1024px)')
    const [sortbyfilter, setSortByFilter] = useState({
        orderby: '15',
        orderby2: '',
        sortType: 2,
    })

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }
    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        socket.on('buyer_assurance', (data) => {
            handler(data, 'buyer_assurance')
        })
        socketAuction.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.off('buyer_assurance', (data) => {
                handler(data, 'buyer_assurance')
            })

            socketAuction.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])

    const active_sorts = {
        sorts: {
            sortcategoryTypes: ['2', '4', '5', '7', '8'],
            sortconditionTypes: ['2', '3', '5', '6'],
            sortsubCategoryTypes: ['1', '2', '3'],
            sortlocations: ['1', '2', '3', '18', '20'],
            sortall_notifications: [],
            sortbusiness_category: [],
            sortvat_registration: [],
        },
    }
    const [searchbar] = useState(new URLSearchParams(window.location.search.substring(1)).get('q'))
    const [catg] = useState(new URLSearchParams(window.location.search.substring(1)).get('cat'))
    const params = new URLSearchParams(window.location.search.substring(1))

    const formik = useFormik({
        initialValues: {
            sh_limit: sessionStorage.getItem('sh_limit'),
            page: 1,
            orderby: global?.storeConfigration?.liquidation_filter_update?.value == 1 ? '15' : '',
            location: [],
            sortType:
                global?.storeConfigration?.liquidation_filter_update?.value == 1 ? 2 : undefined,
            state: [],
            city: [],
            auction_city:
                productLocalState.globalLocationFilter == 1
                    ? [productLocalState.globalLocation]
                    : productLocalState.globalLocationFilter == 2
                    ? [productLocalState.globalLocation?.split(',')?.[0]?.trim()]
                    : [],
            category: catg ? [catg] : [],
            contenthead5: [],
            subcategory: [],
            condition: [],
            storedate: [],
            enddate: null,
            fromprice: 0,
            toprice: 0,
            totalpage: 5,
            lotof: '',
            searchbar: searchbar ? searchbar : '',
            miles: params.get('m') ? params.get('m') : '',
            from: '',
            closed_auction_list: 0,
            view_type: params.get('view') ? params.get('view') : 0,
            auction_io: 1,
            conditionunique: [],
            lotsize: ['1'],
            auction_state:
                productLocalState.globalLocationFilter == 1
                    ? []
                    : productLocalState.globalLocationFilter == 2
                    ? [productLocalState.globalLocation?.split(',')?.[1]?.trim()]
                    : [productLocalState.globalLocation],
            orderby2: global?.storeConfigration?.liquidation_filter_update?.value == 1 ? '' : '6',
            need_bid_increment: 1,
        },
    })

    const [viewFilter, setViewFilter] = useState([
        { id: 0, description: 'All' },
        { id: 1, description: 'Auction Lots' },
        { id: 2, description: 'Buy Now Lots' },
    ])
    let filterValues = [
        {
            title: 'View',
            type: 'radio',
            noLabel: true,
            name: 'view_type',
            int: 1,
            class: 'col-12',
            item: viewFilter,
            formik: formik,
        },

        {
            title: 'Category',
            type: 'check',
            name: 'category',
            options:
                searchCategories.length > 0 && global.storeConfigration?.cat_with_count?.value
                    ? searchCategories
                          .sort((a, b) => a.description.localeCompare(b.description))
                          .filter((main) => {
                              return true
                          })
                    : global.searchCategories
                          .sort((a, b) => a.description.localeCompare(b.description))
                          .filter((main) => {
                              return true
                          }),
            formik: formik,
        },
        // {
        //     title: 'Conditions',
        //     type: 'check',
        //     name: 'conditionunique',
        //     options: allCondition
        //         .filter(
        //             (maincat) =>
        //                 maincat.active === 1 ||
        //                 (formik.values.conditionTypeId &&
        //                     formik.values.conditionTypeId === maincat.id),
        //         )
        //         .map((location) => {
        //             let conditionChanged = {}
        //             conditionChanged.description = location.description
        //             conditionChanged.id = location.id
        //             conditionChanged.name = location.description
        //             conditionChanged.value = location.id
        //             conditionChanged.active = 1
        //             return conditionChanged
        //         })
        //         .sort((a, b) => a.description.localeCompare(b.description))
        //         .filter((main) => {
        //             return true
        //         }),
        //     formik: formik,
        // },
        {
            title: `Location${states.length > 1 ? 's' : ''}`,
            type: 'check',
            name: 'auction_city',
            options: states
                .map((location) => {
                    let conditionChanged = {}
                    conditionChanged.description = location.city
                    conditionChanged.id = location.city
                    conditionChanged.name = location.city
                    conditionChanged.value = location.city
                    conditionChanged.active = 1
                    return conditionChanged
                })
                .filter((main) => {
                    return true
                }),
            formik: formik,
        },
        // {
        //     title: "Seller's",
        //     type: 'check',
        //     name: 'contenthead5',
        //     options: brandsliqutatios
        //         .map((buscat) => {
        //             let busCategoryChanged = {}
        //             busCategoryChanged.description = buscat.description
        //             busCategoryChanged.name = buscat.description
        //             busCategoryChanged.value = buscat.id
        //             busCategoryChanged.id = buscat.id
        //             busCategoryChanged.active = 1
        //             return busCategoryChanged
        //         })
        //         .sort((a, b) => a.description.localeCompare(b.description))
        //         .filter((main) => {
        //             return true
        //         }),
        //     formik: formik,
        // },
        // {
        //     title: 'Lot Size',
        //     type: 'check',
        //     name: 'lotsize',
        //     options: [
        //         {
        //             description: 'Pallet',
        //             name: 'Pallet',
        //             value: 1,
        //             id: 1,
        //         },
        //     ],
        //     formik: formik,
        // },
        {
            title: 'Auction Status',
            type: 'radio',
            noLabel: true,
            name: 'closed_auction_list',
            int: 1,
            class: 'col-12',
            item: [
                { id: 0, description: 'Live Items' },
                { id: 1, description: 'Closed Items' },
            ],
            formik: formik,
        },

        // {
        //     title: 'Sub category',
        //     type: 'check',
        //     name: 'subcategory',
        //     options: global.searchSubCategories.filter((subcat) => {
        //         // if (
        //         //     formik &&
        //         //     formik.values &&
        //         //     formik.values.category &&
        //         //     formik.values.category.length
        //         //         ? formik.values.category.includes(subcat.level_id.toString())
        //         //         : false
        //         // ) {
        //         return true
        //         //}
        //     }),
        //     formik: formik,
        // },
    ]
    useEffect(() => {
        formik.setFieldValue(
            'auction_state',
            productLocalState.globalLocationFilter == 1
                ? []
                : productLocalState.globalLocationFilter == 2
                ? [productLocalState.globalLocation?.split(',')?.[1]?.trim()]
                : [productLocalState.globalLocation],
        )
        formik.setFieldValue(
            'auction_city',
            productLocalState.globalLocationFilter == 1
                ? [productLocalState.globalLocation]
                : productLocalState.globalLocationFilter == 2
                ? [productLocalState.globalLocation?.split(',')?.[0]?.trim()]
                : [],
        )
        if (productLocalState.disableBuynowLocations?.includes(productLocalState.globalLocation)) {
            setViewFilter([{ id: 1, description: 'Auction Lots' }])
            formik.setFieldValue('view_type', 1)
        } else {
            setViewFilter([
                { id: 0, description: 'All' },
                { id: 1, description: 'Auction Lots' },
                { id: 2, description: 'Buy Now Lots' },
            ])
            formik.setFieldValue('view_type', 0)
        }
    }, [productLocalState.globalLocation])
    useEffect(() => {
        setLoading(false)

        // if (search_allproducts.from === 'searchPage') {
        if (search_allproducts.results.length > 0) {
            search_allproducts.results.map((data) => {
                if (data?.extra_fields?.includes('}')) {
                    let parse = JSON.parse(data.extra_fields)
                    Object.keys(parse).map((val) => {
                        data[val] = parse[val]
                    })
                }
            })
            setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
        } else {
            setViewProduct([])
        }
        if (
            search_allproducts?.categoriesCounts?.length > 0 &&
            searchCategories?.length == 0 &&
            global.storeConfigration?.cat_with_count?.value
        ) {
            var new_cat = global.searchCategories
            new_cat.map((value) => {
                value.catcount =
                    search_allproducts.categoriesCounts.filter(
                        (val) => parseInt(val.category_id) == parseInt(value.id),
                    ).length > 0
                        ? search_allproducts.categoriesCounts.filter(
                              (val) => parseInt(val.category_id) == parseInt(value.id),
                          )[0].CountCat
                        : 0
                if (!value.description.includes(value.catcount)) {
                    value.description = `${value.description + ' '}(${value.catcount})`
                }
            })

            setSearchCategories(new_cat)
        }
        setTotalItem(search_allproducts.total_pagecnt)
        setStates(search_allproducts.city)
        // }
    }, [search_allproducts, formik.values])
    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const onHandlePage = (value) => {
        formik.setFieldValue('page', value)
        window.scrollTo(0, 0)
    }
    useEffect(() => {
        if (localStorage.token) {
            setAuthToken(localStorage.token)
        }
        setLoading(true)
        // if (formik.values.closed_auction_list) {
        //     formik.values.orderby = '7'
        // }
        if (formik.values.view_type == 0) {
            formik.setFieldValue('from', '')
            getAllSearchProducts(formik.values)
        } else if (formik.values.view_type == 1) {
            formik.setFieldValue('from', 'auction_only')
            if (formik.values.from === 'auction_only') {
                getAllSearchProducts(formik.values)
            }
        } else if (formik.values.view_type == 2) {
            formik.setFieldValue('from', 'buynow')
            if (formik.values.from === 'buynow') {
                getAllSearchProducts(formik.values)
            }
        }
        window.scrollTo(0, 0)
    }, [isAuthenticated, formik.values])

    useEffect(() => {
        formik.setFieldValue('page', 1)
    }, [formik.values.view_type])
    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        formik.setFieldValue('miles', params.get('m') ? params.get('m') : '')
        formik.setFieldValue('searchbar', params.get('q') ? params.get('q') : '')
        formik.setFieldValue('view_type', params.get('view') ? params.get('view') : 0)
    }, [window.location.search])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    const handleClose = () => {
        params.delete('product')
        history.replace({
            pathname: location.pathname,
            search: params.toString(),
        })
        setSelectedProduct(null)
    }

    const handleClick = async (id) => {
        if (props.fromComponent === 'home') {
            setSelectedProduct(id)
        } else {
            params.set('product', id)

            history.replace({
                pathname: location.pathname,
                search: params.toString(),
            })
        }
    }

    useEffect(() => {
        var product_id = params.get('product')
        if (product_id && !selectedProduct) {
            setSelectedProduct(product_id)
        }
    }, [window.location.search])
    const pageOptions = [
        {
            value: '25',
            show: 'Results 25 per page',
        },
        {
            value: '50',
            show: 'Results 50 per page',
        },
        {
            value: '100',
            show: 'Results 100 per page',
        },
        {
            value: '150',
            show: 'Results 150 per page',
        },
    ]

    const sortShow1 = [
        {
            value: 15,
            show: 'Todays Top Deals',
        },
        {
            value: 3,
            show: 'Current price low to high',
        },
        {
            value: 11,
            show: 'Current price high to low',
        },

        {
            value: 2,
            show: 'Recently listed',
        },
        {
            value: '',
            show: 'Deals Ending Soon',
        },
        {
            value: 14,
            show: 'Best Deals',
        },
    ]

    const sortShow = [
        {
            value: '',
            show: 'None',
        },
        {
            value: 3,
            show: 'Current price low to high',
        },
        {
            value: 11,
            show: 'Current price high to low',
        },

        {
            value: 2,
            show: 'Recently listed',
        },
    ]
    const [checked, setChecked] = useState(
        global?.storeConfigration?.liquidation_filter_update?.value == 1 ? false : true,
    )
    const settings = {
        dots: true,
        fade: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        cssEase: 'linear',
    }
    const handleRedirect = (data) => {
        // console.log(data)
        // console.log(global.multipleBannersFields[data])
        if (!isAuthenticated) {
            handleRedirectInternal(history, 'sign-up')
        } else {
            // window.location.href = JSON.parse(
            //     global.multipleBannersFields[data].fields
            //         ? global.multipleBannersFields[data].fields
            //         : '{}',
            // ).btn_link
        }
    }
    const handleSortByFilter = (event) => {
        const value = event.target.value
        if (value == '14') {
            setSortByFilter((prevValue) => {
                return {
                    ...prevValue,
                    orderby: value,
                    orderby2: '',
                    sortType: 1,
                }
            })
        } else if (value == '15') {
            setSortByFilter((prevValue) => {
                return {
                    ...prevValue,
                    orderby: value,
                    orderby2: '',
                    sortType: 2,
                }
            })
        } else if (value == '') {
            setSortByFilter((prevValue) => {
                return {
                    ...prevValue,
                    orderby: value,
                    orderby2: '6',
                    sortType: '',
                }
            })
        } else if (value == '11') {
            setSortByFilter((prevValue) => {
                return {
                    ...prevValue,
                    orderby: value,
                    orderby2: '',
                    sortType: '',
                }
            })
        } else if (value == '3') {
            setSortByFilter((prevValue) => {
                return {
                    ...prevValue,
                    orderby: value,
                    orderby2: '',
                    sortType: '',
                }
            })
        } else if (value == '2') {
            setSortByFilter((prevValue) => {
                return {
                    ...prevValue,
                    orderby: value,
                    orderby2: '',
                    sortType: '',
                }
            })
        }
    }
    useEffect(() => {
        if (global?.storeConfigration?.liquidation_filter_update?.value == 1) {
            if (formik.values.orderby !== sortbyfilter.orderby) {
                console.log('came to sort api')
                formik.setValues({
                    ...formik.values,
                    orderby: sortbyfilter.orderby,
                    orderby2: sortbyfilter.orderby2,
                    sortType: sortbyfilter.sortType,
                })
            }
        }
    }, [sortbyfilter])
    useEffect(() => {
        if (checked) {
            formik.setFieldValue('orderby2', `6`)
        } else {
            formik.setFieldValue('orderby2', ``)
        }
    }, [checked])
    useEffect(() => {
        if (
            global?.storeConfigration?.liquidation_filter_update?.value !== '1' &&
            formik.values.orderby == '2'
        ) {
            formik.setFieldValue('orderby2', '')
            setChecked(false)
        }
    }, [formik.values.orderby])

    return (
        <Layout props={props}>
            <SEO title={`${global?.storeDetails?.name} | Search`} />
            {/* {global.multipleBannersFields.length ? (
                <div className="siteBanner multiple">
                    {
                        <Slider {...settings}>
                            {global.multipleBannersFields?.map((data, index) => (
                                <>
                                    <img
                                        src={
                                            process.env.REACT_APP_AUCTION_IMAGE_URL +
                                            '/' +
                                            data?.image_name
                                        }
                                    />
                                    <div className="bannerContent">
                                        <h2 className="pb-4">
                                            {JSON.parse(data.fields ? data.fields : '{}').title}
                                        </h2>
                                        <PrimaryButton
                                            label={
                                                isAuthenticated
                                                    ? JSON.parse(data.fields ? data.fields : '{}')
                                                          .btn_text
                                                    : 'Sign Up'
                                            }
                                            type="button"
                                            onClick={() =>
                                                !isAuthenticated
                                                    ? handleRedirectInternal(history, 'sign-up')
                                                    : (window.location.href = JSON.parse(
                                                          global.multipleBannersFields[index].fields
                                                              ? global.multipleBannersFields[index]
                                                                    .fields
                                                              : '{}',
                                                      ).btn_link)
                                            }
                                        />
                                    </div>
                                </>
                            ))}
                        </Slider>
                    }
                </div>
            ) : (
                <div className="siteBanner">
                    <LazyLoadImage
                        src={global?.storeDetails?.bannerValue}
                        alt={`${global?.storeDetails?.name} banner`}
                        effect="blur"
                        height="100%"
                        width="100%"
                    />
                    <div className="bannerContent">
                        <h2>{global?.storeDetails?.description}</h2>
                        <PrimaryButton
                            label="Explore Now"
                            onClick={() =>
                                handleRedirectInternal(
                                    history,
                                    `${isAuthenticated ? 'search' : 'sign-up'}`,
                                )
                            }
                        />
                    </div>
                </div>
            )} */}

            <div className="customContainer mt-5 position-relative " id="search-view">
                {forMobile ? (
                    <>
                        <div className="auctionsMiscFilter">
                            {global?.storeConfigration?.liquidation_filter_update?.value ==
                            1 ? null : (
                                <CheckBox
                                    name="orderby"
                                    label="Time Remaining"
                                    className="asdasd"
                                    checked={checked}
                                    onChange={(event) => setChecked(!checked)}
                                />
                            )}

                            <div className="gridListToggle">
                                <Button
                                    className={auctionView === 'Grid' ? 'active' : ''}
                                    onClick={() => setAuctionView('Grid')}
                                >
                                    <span className="material-icons">apps</span>
                                </Button>
                                <Button
                                    className={auctionView === 'List' ? 'active' : ''}
                                    onClick={() => setAuctionView('List')}
                                >
                                    <span className="material-icons">view_list</span>
                                </Button>
                            </div>
                        </div>
                        <div className="mblStckyFilter py-3">
                            <CustomSelect
                                label="Results per page"
                                name="resultsPerPage"
                                selectType="noBorder"
                                value={formik.values.sh_limit}
                                size="small"
                                onChange={(event, editor) => {
                                    formik.values.page = 1
                                    formik.setFieldValue('sh_limit', event.target.value)
                                    sessionStorage.setItem('sh_limit', event.target.value)
                                }}
                            >
                                {pageOptions.map((opt, optindex) => (
                                    <option value={opt.value}>{opt.show}</option>
                                ))}
                            </CustomSelect>

                            <CustomSelect
                                label="Sort by"
                                value={formik.values.orderby}
                                size="small"
                                selectType="noBorder"
                                name="orderby"
                                // shrink={search.orderby !== '' ? true : false}
                                onChange={(event, editor) => {
                                    {
                                        global?.storeConfigration?.liquidation_filter_update
                                            ?.value == 1 &&
                                        global?.storeConfigration?.liquidation_filter_update
                                            ?.value !== undefined
                                            ? handleSortByFilter(event)
                                            : formik.setFieldValue('orderby', event.target.value)
                                    }
                                }}
                            >
                                {global?.storeConfigration?.liquidation_filter_update?.value == 1
                                    ? sortShow1.map((opt, optindex) => (
                                          <option value={opt.value}>{opt.show}</option>
                                      ))
                                    : sortShow.map((opt, optindex) => (
                                          <option value={opt.value}>{opt.show}</option>
                                      ))}
                            </CustomSelect>
                            <Button
                                className="filterButton w-100"
                                variant="outlined"
                                onClick={toggleDrawer('bottom', true)}
                            >
                                Filters
                            </Button>
                            <Button
                                className="filterButton w-100"
                                variant="outlined"
                                onClick={() => handleRedirectInternal(history, 'dashboard/mybids')}
                            >
                                My Bids
                            </Button>

                            {/* <Button
                                className="filterButton srchBtn w-100"
                                variant="outlined"
                                onClick={toggleDrawer('top', true)}
                            >
                                <span className="material-icons">search</span>
                            </Button> */}
                        </div>

                        <div className="auctionSearchPaginationTop">
                            <PaginationComponent
                                onPageChange={onHandlePage}
                                totalProductCount={totalItems}
                                currentPage={formik.values.page}
                                resultToShow={formik.values.sh_limit}
                            />
                        </div>
                    </>
                ) : (
                    <div className="auctionSearchPaginationTop d-flex justify-content-between align-items-center flex-wrap my-5">
                        <PaginationComponent
                            onPageChange={(value) => {
                                document.getElementById('search-view').scrollIntoView()
                                onHandlePage(value)
                            }}
                            totalProductCount={totalItems}
                            currentPage={formik.values.page}
                            resultToShow={formik.values.sh_limit}
                        />

                        <div className="auctionsMiscFilter">
                            <div className="gridListToggle">
                                <Button
                                    className={auctionView === 'Grid' ? 'active' : ''}
                                    onClick={() => setAuctionView('Grid')}
                                >
                                    <span className="material-icons">apps</span>
                                </Button>
                                <Button
                                    className={auctionView === 'List' ? 'active' : ''}
                                    onClick={() => setAuctionView('List')}
                                >
                                    <span className="material-icons">view_list</span>
                                </Button>
                            </div>
                            <CustomSelect
                                label="Results per page"
                                name="resultsPerPage"
                                selectType="noBorder"
                                value={formik.values.sh_limit}
                                size="small"
                                onChange={(event, editor) => {
                                    formik.values.page = 1
                                    formik.setFieldValue('sh_limit', event.target.value)
                                    sessionStorage.setItem('sh_limit', event.target.value)
                                }}
                            >
                                {pageOptions.map((opt, optindex) => (
                                    <option value={opt.value}>{opt.show}</option>
                                ))}
                            </CustomSelect>
                            <CustomSelect
                                label="Sort by"
                                value={formik.values.orderby}
                                size="small"
                                selectType="noBorder"
                                name="orderby"
                                // shrink={search.orderby !== '' ? true : false}
                                onChange={(event, editor) => {
                                    {
                                        global?.storeConfigration?.liquidation_filter_update
                                            ?.value == 1 &&
                                        global?.storeConfigration?.liquidation_filter_update
                                            ?.value !== undefined
                                            ? handleSortByFilter(event)
                                            : formik.setFieldValue('orderby', event.target.value)
                                    }
                                }}
                            >
                                {global?.storeConfigration?.liquidation_filter_update?.value == 1
                                    ? sortShow1.map((opt, optindex) => (
                                          <option value={opt.value}>{opt.show}</option>
                                      ))
                                    : sortShow.map((opt, optindex) => (
                                          <option value={opt.value}>{opt.show}</option>
                                      ))}
                            </CustomSelect>
                        </div>

                        <Button
                            className="toggleRespDrawer w-100"
                            variant="outlined"
                            onClick={toggleDrawer('bottom', true)}
                        >
                            <span className="material-icons">tune</span>
                            Filters
                        </Button>
                        {global?.storeConfigration?.liquidation_filter_update?.value == 1 ? null : (
                            <CheckBox
                                name="orderby"
                                label="Time Remaining"
                                className="asdasd"
                                checked={checked}
                                onChange={(event) => setChecked(!checked)}
                            />
                        )}
                    </div>
                )}

                <div className="searchContainer">
                    <div className="searchLt">
                        <FilterPanel formik={formik} filterValues={filterValues} allOpen={true} />
                    </div>

                    <div className="searchRt">
                        {isLoading ? (
                            <div>
                                <Loaders name="product_grid_view" isLoading={isLoading} loop={6} />
                            </div>
                        ) : viewProduct?.length !== 0 ? (
                            <div
                                className={`lotResults mb-4 ${auctionView} liquidationBuyCard ${
                                    !isAuthenticated ? 'unauth' : 'auth'
                                }`}
                            >
                                {viewProduct.map((data, index) =>
                                    auctionView === 'Grid' ? (
                                        <>
                                            {
                                                <GridProduct
                                                    data={data}
                                                    viewProduct={viewProduct}
                                                    setViewProduct={setViewProduct}
                                                    className={data.auction ? 'auction' : 'buynow'}
                                                    key={index}
                                                    type={data.auction ? 'auction' : 'buynow'}
                                                    selectedProduct={selectedProduct}
                                                    link={`/gallery/product-view/${data.id}`}
                                                    handleClick={handleClick}
                                                    timerTheme=""
                                                    bidStatus={true}
                                                    sliderTheme="multiseller"
                                                    currencyType="cad"
                                                    timerIcon={true}
                                                    cardTheme="liquidationTwo"
                                                    bidBtnLabel="Place Bid"
                                                />
                                            }
                                        </>
                                    ) : (
                                        <MultisellerListCard
                                            cardTheme="liquidationTwo"
                                            lotData={data}
                                            viewProduct={viewProduct}
                                            setViewProduct={setViewProduct}
                                            className={data.auction ? 'auction' : 'buynow'}
                                            key={index}
                                            bidStatus={true}
                                            selectedProduct={selectedProduct}
                                            allsearchitems={viewProduct}
                                            type="lot"
                                            handleClick={handleClick}
                                            details={`/gallery/product-view/${data.id}`}
                                            sliderTheme="multiseller"
                                            card_theme="liquidationTwo"
                                            bidBtnLabel={
                                                <>
                                                    <span className="material-icons-outlined">
                                                        gavel
                                                    </span>
                                                    Bid
                                                </>
                                            }
                                        />
                                    ),
                                )}
                            </div>
                        ) : (
                            <NoRecordsFound />
                        )}
                        <PaginationComponent
                            onPageChange={(value) => {
                                document.getElementById('search-view').scrollIntoView()
                                onHandlePage(value)
                            }}
                            totalProductCount={totalItems}
                            currentPage={formik.values.page}
                            resultToShow={formik.values.sh_limit}
                        />
                    </div>
                </div>
            </div>

            <Drawer
                anchor="bottom"
                open={state['bottom']}
                className="responsiveFilterDrawer"
                onClose={toggleDrawer('bottom', false)}
                onOpen={toggleDrawer('bottom', true)}
            >
                <div
                    className={clsx(classes.list, 'bottom')}
                    role="presentation"
                    // onClick={toggleDrawer('bottom', false)}
                    onKeyDown={toggleDrawer('bottom', false)}
                >
                    <FilterPanel formik={formik} filterValues={filterValues} />
                </div>
            </Drawer>
            <MultisellerSlider
                storeTheme="Liquidation_two"
                selectedLot={selectedProduct}
                handleClose={handleClose}
                currency="cad"
                type={props.type ? props.type : ''}
            />
            <React.Fragment>
                <SwipeableDrawer
                    className="respHeaderSearch"
                    anchor={'top'}
                    open={state['top']}
                    disableBackdropTransition={!iOS}
                    disableDiscovery={iOS}
                    disableSwipeToOpen={false}
                    onClose={toggleDrawer('top', false)}
                    onOpen={toggleDrawer('top', true)}
                >
                    <HeaderSearchComponent />
                </SwipeableDrawer>
            </React.Fragment>
        </Layout>
    )
}

export default AuctionSearchComponent
