import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { currencyFormat, handleRedirectInternal, mapData } from '../../../Product/common/components'
import UserActivity from '../UserActivity'
import ReferralEarned from './Earned'
import ReferralSpent from './Spent'
import Copy from '../Share/Copy'
import { useContext } from 'react'
import authContext from '../../../Product/context/auth/authContext'
import Popup from '../../../Product/components/organisms/Popup'
import { useFormik } from 'formik'
const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
            className="customTabsInner dashboard-tabs"
            // component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}
function a11yProps(index) {
    return {
        id: `buyer-dashboard-${index}`,
        'aria-controls': `buyer-dashboard-tabpanel-${index}`,
    }
}
const Bucks = () => {
    const { user } = useContext(authContext)
    const history = useHistory()
    const search = useLocation().search
    const params = new URLSearchParams(search)
    const referralLink = `${global?.storeDetails?.dmap_url?.replace(
        'search',
        '',
    )}sign-up?ref=${btoa(user?.email)}`
    const [value, setValue] = useState(params.get('s') ? parseInt(params.get('s')) : 0)
    const [macks, setMacks] = useState(false)
    const { t } = useTranslation()
    const handleChange = (event, newValue) => {
        handleRedirectInternal(history, `referral?s=${newValue}`)
    }
    useEffect(() => {
        setValue(params.get('s') ? parseInt(params.get('s')) : 0)
    }, [window.location.search])
    const formik = useFormik({
        initialValues: {
            apply_discount: false,
        },
    })
    const checkbox_arr = [
        {
            label: ['Auto apply to next invoice'],
            name: 'apply_discount',
            type: 'checkbox_bid',
            class: 'col-md-12',
            formik: formik,
            onChange: () => {
                formik.setFieldValue('apply_discount', !formik.values.apply_discount)
                // formik.handleSubmit()
            },
        },
    ]
    useEffect(() => {
        if (user) {
            formik.values.apply_discount = user.apply_discount ? true : false
        }
    }, [user])
    return (
        <div>
            {global.pluginConfiguration?.referral?.enable == 1 &&
            global.storeConfigration?.referral_discount?.value == 1 ? (
                <>
                    {/* <h2 className="mp-head mb-4">Referral Link -</h2> */}
                    <img className="referralPrime my-3" src="/assets/images/referralPrime.png" />
                    <p className="referalLinks d-flex mt-3 mb-0">
                        <a href={referralLink} className="mr-2" target="_blank" rel="noreferrer">
                            Your Referral Link
                        </a>
                        <Copy text={referralLink} textOnly={true} />
                    </p>
                </>
            ) : null}

            {global.pluginConfiguration?.promo_code?.enable ? (
                <div className="accChild">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <p className="mp-head" style={{ fontSize: '20px' }}>
                            You currently have {currencyFormat(user?.discount_amount)}{' '}
                            <i style={{ color: '#fcb444' }}>in PRIME Bucks!</i>
                        </p>
                        <div className="row">{Object.values(mapData(checkbox_arr))}</div>
                    </div>

                    <p>
                        See{' '}
                        <a onClick={() => setMacks(true)}>
                            {' '}
                            <i style={{ color: '#fcb444', cursor: 'pointer' }}>
                                {' '}
                                What are Prime Bucks?
                            </i>{' '}
                        </a>
                    </p>
                </div>
            ) : (
                ''
            )}

            <Popup
                open={macks}
                handleClose={(e) => setMacks(false)}
                size="md"
                className="save-search-modal"
                modaltitle="What are Prime Bucks?"
            >
                <>
                    <div className="row pt-2 pl-3 pr-3 pb-3">
                        Prime Bucks are a promotional virtual currency on primebidsauction.com that
                        you can apply towards won auction lot invoices. A positive Prime Bucks
                        balance will be automatically applied to the next invoice by default. You
                        may toggle the default behavior by checking/unchecking the option in your
                        account. Prime Bucks may only be used by the primebidsauction.com account
                        holder, and are not transferrable to cash.
                    </div>
                </>
            </Popup>
        </div>
    )
}

export default Bucks
