import React from 'react'
import { NavLink } from 'react-router-dom'
import { Copyrightinfo, Logo } from '../../../utils'
function BeachFooter() {
    return (
        <footer className="footer-main-standard noPrint beachFooter">
            <div className="customContainer">
                <div className="row" data-gutter="60">
                    <div className="col-12 ftrDtlCntnr">
                        <div className="footLogoCnt">
                            <Logo className="footerLogo" />
                            <span className="d-md-block d-none">
                                <Copyrightinfo />
                            </span>
                        </div>
                        <div className="footerContent">
                            <div>
                                <h5>Get to know us</h5>
                                <ul className="footerLinks">
                                    <li>
                                        <NavLink activeClassName="active" to="/how_it_works">
                                            Careers
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/aboutus">
                                            About us
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h5>Buying info</h5>
                                <ul className="footerLinks">
                                    <li>
                                        <NavLink activeClassName="active" to="/how_it_works">
                                            How to guide
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/faq">
                                            FAQ
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h5>Sell with Us</h5>
                                <ul className="footerLinks">
                                    <li>
                                        <NavLink activeClassName="active" to="/how_it_works">
                                            Why sell with us
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/faq">
                                            Become a Seller
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/faq">
                                            Seller Tools Need Help?
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h5>Contact Us</h5>
                                <ul className="footerLinks">
                                    <li>pradeepa+bl@auctionsoftware.com</li>
                                    <li>+1 843-278-8400</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default BeachFooter
