import React, { useState, useEffect, useContext } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { handleRedirectInternal, jsonToFormData, mapData } from '../../Product/common/components'
import { Link, useHistory } from 'react-router-dom'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import AuthContext from '../../Product/context/auth/authContext'
import axios from 'axios'
import { swapCountry } from '../commonFunctions'
import Captch from '../../Themes/MultiAuction/Registration/Captch'
import Popup from '../../Product/components/organisms/Popup'
import AlertContext from '../../Product/context/alert/alertContext'
import jsonp from 'jsonp'
import queryString from 'query-string'
import sha256 from 'crypto'
import pluginContext from '../../Product/context/plugin/pluginContext'
import productContext from '../../Product/context/product/productContext'
function SignupFunction({
    multipleUserRole,
    title,
    subTitle,
    regpro,
    defaultCountryCode,
    termsCondition,
    needCountrybox,
    captcha,
    phone_country_code_must,
}) {
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const { getAlldynamic, dynamicproducts } = useContext(productContext)
    const [fields, setFields] = useState({})
    let searchParams = new URLSearchParams(window.location.search)
    let {
        register,
        responseStatus,
        logout,
        isAuthenticated,
        verifyemailandsmscode,
        clearResponse,
    } = authContext
    const { createReferral } = useContext(pluginContext)
    const { setAlert } = useContext(AlertContext)
    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const [terms_condition, setTermscondition] = useState(false)
    const [reg_req_data, setRegistrationdata] = useState({})
    const [otppopup, setOtppopup] = useState(false)
    const [emailotp, setEmailotp] = useState('')
    const [mobileotp, setMobileotp] = useState('')
    const [loading, setLoading] = useState(false)
    const [othersField, setOtherField] = useState(false)
    const validationArray = Yup.object().shape({
        first_name:
            global.storeConfigration?.signup_page_changes?.value !== '1'
                ? Yup.string()
                      .trim()
                      .matches(
                          /^[a-zA-Z\s]*$/g,
                          'The special characters and numbers are not allowed!',
                      )
                      .max(20, 'Maximum 20 characters')
                      .required('Required')
                : undefined,
        last_name:
            global.storeConfigration?.signup_page_changes?.value !== '1'
                ? Yup.string()
                      .trim()
                      .matches(
                          /^[a-zA-Z\s]*$/g,
                          'The special characters and numbers are not allowed!',
                      )
                      .max(20, 'Maximum 20 characters')
                      .required('Required')
                : undefined,
        email: Yup.string()
            .email('Invalid email format')
            .max(250, 'Maximum 250 characters')
            .required('Required'),
        phone: phone_country_code_must
            ? Yup.string()
                  .trim()
                  .matches(/^\+/, 'Please enter country code')
                  .matches(/^[+]?[0-9]+$/, 'Only numbers are allowed')
                  .min(12, 'Invalid Number!')
                  .max(16, 'Maximum 14 characters')
                  .required('Required')
            : global?.storeConfigration?.ten_digit_phone_number?.value == 1
            ? Yup.string()
                  .trim()
                  .matches(/^[+]?[0-9]+$/, 'Only numbers are allowed')
                  .min(10, 'Invalid!')
                  .max(10, 'Invalid!')
                  .required('Required')
            : Yup.string()
                  .trim()
                  .matches(/^[+]?[0-9]+$/, 'Only numbers are allowed')
                  .min(10, 'Invalid!')
                  .max(15, 'Maximum 14 characters')
                  .required('Required'),
        password:
            global?.storeConfigration?.strong_password?.value == 1
                ? Yup.string()
                      .matches(/^\S*$/, 'White Spaces Not Allowed')
                      .matches(
                          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$&*])(?=.*\d)[A-Za-z\d]/,
                          'Must contain One Uppercase, One Lowercase, One Number,One Special Characters',
                      )
                      .min(8, 'Minimum 8 characters')
                      .required('Required')
                : Yup.string()
                      .matches(/^\S*$/, 'White Spaces Not Allowed')
                      .min(8, 'Minimum 8 characters')
                      .required('Required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password')], 'Passwords not match')
            .required('Required'),
        country:
            global.storeConfigration?.signup_page_changes?.value !== '1'
                ? Yup.string().trim().required('Required')
                : undefined,
        state:
            global.storeConfigration?.signup_page_changes?.value !== '1'
                ? Yup.string().trim().required('Required')
                : undefined,
        address1:
            global.storeConfigration?.signup_page_changes?.value !== '1'
                ? Yup.string()
                      .trim()
                      // .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'Special characters not allowed')
                      .max(250, 'Maximum 250 characters')
                      .required('Required')
                : undefined,
        address2:
            global.storeConfigration?.signup_page_changes?.value !== '1'
                ? Yup.string()
                      .trim()
                      // .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'Special characters not allowed')
                      .max(250, 'Maximum 250 characters')
                : undefined,
        city:
            global.storeConfigration?.signup_page_changes?.value !== '1'
                ? Yup.string()
                      .trim()
                      .matches(/^[_A-zA-Z]*((-|\s)*[_A-zA-Z])*$/g, 'Only Alphabets are allowed')
                      .required('Required')
                : undefined,
        zip:
            global.storeConfigration?.signup_page_changes?.value !== '1'
                ? Yup.string()
                      .when([], {
                          is: () => formik?.values.country != 156,
                          then: Yup.string().required('Required!'),
                          otherwise: Yup.string().notRequired(),
                      })
                      .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'Special characters not allowed')
                      .min(5, 'Minimum 5 characters')
                      .max(7, 'Maximum 7 characters')
                : undefined,
        captch:
            global.storeConfigration?.signup_page_changes?.value !== '1'
                ? captcha
                    ? Yup.boolean().oneOf([true]).required('Required')
                    : Yup.string()
                : undefined,
        termCheck:
            global?.storeConfigration?.terms_con_must_reg?.value == 1 ||
            global?.storeConfigration?.remove_signup_terms?.value == 1
                ? ''
                : Yup.boolean()
                      .oneOf([true], 'Please accept Terms and Conditions')
                      .required('Please accept Terms and Conditions'),
        card_profileid:
            global.storeConfigration?.signup_page_changes?.value !== '1'
                ? Yup.string().when('role', {
                      is: '1' || 1,
                      then: Yup.string().trim().required('Required'),
                  })
                : undefined,
        heard_from: othersField ? Yup.string().trim().required('Required') : undefined,
    })

    const requiredfields = {
        role: multipleUserRole ? 1 : 0,
        first_name: '',
        last_name: '',
        email: '',
        phone_number_new: '',
        phone:
            global?.storeConfigration?.ten_digit_phone_number?.value == 1
                ? ''
                : global?.storeConfigration?.default_phone_code?.value
                ? global?.storeConfigration?.default_phone_code?.value
                : '+1',
        password: '',
        confirmPassword: '',
        country: defaultCountryCode ? defaultCountryCode : '',
        state: '',
        city: '',
        address1: '',
        address2: '',
        tempAddress: '',
        zip: '',
        captch: false,
        termCheck: 0,
        subscribe: 0,
        card_profileid: '',
        community: 'auction_io',
        is_auctionio: 1,
        status:
            global.storeConfigration?.need_reg_verification_email?.value == 1 ||
            global.storeConfigration?.need_admin_verification?.value == 1
                ? 'unverified'
                : '',
        redirectURL:
            global.storeConfigration?.need_reg_verification_email?.value == 1
                ? `${window.location.href.split('/sign-up')[0]}/activateaccount`
                : '',
        referral: '',
        account_id:
            global.storeConfigration?.enable_payment_gateway_register?.value == 1
                ? process.env.REACT_APP_AUCTIONPAYID
                : undefined,
        description:
            global.storeConfigration?.enable_payment_gateway_register?.value == 1
                ? 'Registration Customer Creation'
                : undefined,
        register_to_payment_gateway:
            global.storeConfigration?.enable_payment_gateway_register?.value,
        buyer_document_2: [],
        heard_from: '',
    }

    const formik = useFormik({
        initialValues: fields.dynamic_fields
            ? { ...requiredfields, ...fields.dynamic_fields }
            : requiredfields,
        validationSchema: fields.dynamic_validation ? fields.dynamic_validation : validationArray,
        enableReinitialize: true,
        onSubmit: async (val) => {
            let values = { ...val }
            values.zipcode = values.zip
            setLoading(true)
            if (dynamicproducts.length > 0) {
                if (dynamicproducts[0].form_json.includes('[{')) {
                    var dynamic_data = JSON.parse(dynamicproducts[0].form_json),
                        final_dynamic_data = []
                    dynamic_data.map((va) => {
                        final_dynamic_data.push({ ...va, ...{ user_value: values[va.name] } })
                    })
                    values.dynamic_fields = JSON.stringify(final_dynamic_data)
                }
            }
            var tax_exempt =
                typeof global.storeConfigration?.remove_state_from_tax_exempt?.value === 'string'
                    ? global.storeConfigration?.remove_state_from_tax_exempt?.value
                    : ''
            //console.log(tax_exempt)
            if (tax_exempt) {
                var find_state = tax_exempt.split(',').find((val) => val === values.state)
                if (!find_state) {
                    values.tax_excempt = 1
                }
            }
            if (global.storeConfigration?.referral_value?.value) {
                values.heard_from =
                    values.referral == 1
                        ? 'Friend Referral'
                        : values.referral == 2
                        ? 'Email'
                        : values.referral == 3
                        ? 'Auction.io'
                        : values.referral == 4
                        ? 'Google'
                        : values.referral == 5
                        ? 'Facebook'
                        : values.referral == 6
                        ? 'Instagram'
                        : values.referral == 7
                        ? 'Auction Event'
                        : values.referral == 8
                        ? 'Yelp'
                        : `Don't Want to Disclose`
            }

            values.username = values.first_name + values.last_name
            if (global?.storeConfigration?.disable_phone_country_code?.value == 1) {
                values.phone = `+1${values.phone}`
            }
            if (global?.storeConfigration?.terms_con_must_reg?.value == 1) {
                setRegistrationdata(values)
                setTermscondition(true)
            } else if (
                global?.storeConfigration?.otp_verification_mobile_number?.value == 1 ||
                global?.storeConfigration?.otp_verification_email?.value == 1
            ) {
                if (global?.storeConfigration?.otp_verification_email?.value == 1) {
                    values.email_verification = 1
                }
                if (global?.storeConfigration?.otp_verification_mobile_number?.value == 1) {
                    values.mobile_varification = 1
                    values.userPhone = values.phone
                }
                verifyemailandsmscode(values)
                setRegistrationdata(values)
                //setOtppopup(true)
            } else {
                if (global.storeConfigration?.display_register_doument_field?.value == 1) {
                    let sendData = jsonToFormData(values)
                    register(sendData)
                } else {
                    register(values)
                }
            }
        },
    })
    var data = []
    /*eslint-disable*/
    // if (multipleUserRole) {
    //     data.push({
    //         title: '',
    //         type: 'radio',
    //         noLabel: true,
    //         name: 'role',
    //         int: 1,
    //         class: 'col-12 mb-3',
    //         item: [
    //             { id: 0, description: 'Buyer' },
    //             { id: 1, description: 'Seller' },
    //         ],
    //         formik: formik,
    //     })
    // }

    data.push(
        {
            label: 'First name' + '*',
            name: 'first_name',
            type: 'text',
            placeholder: 'Enter your first name',
            class:
                global.storeConfigration?.signup_page_changes?.value !== '1'
                    ? 'col-12 col-sm-6'
                    : 'd-none',
            autoFocus: true,
        },
        {
            label: 'Last name' + '*',
            name: 'last_name',
            type: 'text',
            placeholder: 'Enter your last name',
            class:
                global.storeConfigration?.signup_page_changes?.value !== '1'
                    ? 'col-12 col-sm-6'
                    : 'd-none',
        },
        {
            label: 'Email address' + '*',
            name: 'email',
            type: 'email',
            placeholder: 'Enter your email address',
            class: 'col-12 col-sm-6',
        },
        {
            label: 'Mobile number' + '*',
            name: 'phone',
            type: 'text',
            placeholder: 'Enter your mobile number',
            class: 'col-12 col-sm-6',
            countryCodeEditable: false,
            startAdornment:
                global?.storeConfigration?.disable_phone_country_code?.value == 1 ? '+1' : '',
        },
        {
            label: 'Password' + '*',
            name: 'password',
            type: 'password',
            placeholder: 'Enter your password',
            class: 'col-12 col-sm-6',
            disablePaste: true,
        },
        {
            label: 'Confirm password' + '*',
            name: 'confirmPassword',
            type: 'password',
            placeholder: 'Re enter your password',
            class: 'col-12 col-sm-6',
            disablePaste: true,
        },
        {
            label: 'Address Line 1' + '*',
            name: 'address1',
            type: global.storeConfigration?.map_input?.value == '1' ? 'mapInput' : 'text',
            placeholder: 'Enter your address line 1',
            class:
                global.storeConfigration?.signup_page_changes?.value !== '1'
                    ? 'col-12 col-sm-6 mb-4'
                    : 'd-none',
        },
        {
            label: 'Address Line 2',
            name: 'address2',
            type: 'text',
            placeholder: 'Enter your address line 2',
            class:
                global.storeConfigration?.signup_page_changes?.value !== '1'
                    ? 'col-12 col-sm-6'
                    : 'd-none',
        },
    )

    if (
        (defaultCountryCode === undefined || defaultCountryCode == '' || needCountrybox == 1) &&
        global.storeConfigration?.signup_page_changes?.value !== '1'
    ) {
        data.push({
            label: 'Country' + '*',
            placeholder: 'Select your country',
            class:
                global.storeConfigration?.signup_page_changes?.value !== '1'
                    ? 'col-12 col-sm-6'
                    : 'd-none',
            type: 'select',
            options: swapCountry(countryList).map((country) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = country.name
                busCategoryChanged.value = country.location_id
                return busCategoryChanged
            }),
            name: 'country',
        })
    }
    const referralOptions = [
        { id: 1, description: 'Friend Referral' },
        { id: 2, description: 'Email' },
        { id: 3, description: 'Auction.io' },
        { id: 4, description: 'Google' },
        { id: 5, description: 'Facebook' },
        { id: 6, description: 'Instagram' },
        { id: 7, description: 'Auction Event' },
        { id: 8, description: 'Yelp' },
        { id: 9, description: `Don't Want to Disclose` },
        { id: 10, description: 'Others' },
    ]
    data.push(
        {
            label: defaultCountryCode == '38' ? 'Province' + '*' : 'State' + '*',
            placeholder: defaultCountryCode == '38' ? 'Select your province' : 'Select your state',
            class:
                global.storeConfigration?.signup_page_changes?.value !== '1'
                    ? 'col-12 col-sm-6'
                    : 'd-none',
            type: 'select',
            options: stateList.map((buscat) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = buscat.name
                busCategoryChanged.value = buscat.name
                return busCategoryChanged
            }),
            name: 'state',
        },
        {
            label: 'City' + '*',
            name: 'city',
            type: 'text',
            placeholder: 'Enter your city',
            class:
                global.storeConfigration?.signup_page_changes?.value !== '1'
                    ? 'col-12 col-sm-6'
                    : 'd-none',
        },
        {
            label: defaultCountryCode == '38' ? 'Postal Code' + '*' : 'Zip code' + '*',
            placeholder:
                defaultCountryCode == '38' ? 'Enter your postal code' : 'Enter your zip code',
            class:
                global.storeConfigration?.signup_page_changes?.value !== '1'
                    ? `col-12 col-sm-6 ${formik?.values?.country == 156 ? 'd-none' : ''}`
                    : 'd-none',
            type: 'text',
            name: 'zip',
        },
        // {
        //     label: 'Account Id',
        //     placeholder: 'Enter your account id',
        //     class: `col-12 col-sm-6 ${formik.values.role == 0 ? 'd-none' : 'd-block'}`,
        //     type: 'text',
        //     name: 'card_profileid',
        //     additionalText: (
        //         <a
        //             target="_blank"
        //             rel="noreferrer"
        //             href={
        //                 process.env.REACT_APP_AUCTIONPAY_OTHER_URL.includes('auctionsoftware.com')
        //                     ? 'https://payment.auctionsoftware.com/login'
        //                     : 'https://paymantgateway.io/login'
        //             }
        //         >
        //             {"Don't"} have an account ID ? Click Here
        //         </a>
        //     ),
        // },
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'buyer_document_2',
            titleText: "Driver's License Upload",
            innerText: (
                <div className="text-center">
                    <span className="material-icons">note_add</span>
                    <br />
                    <p className="mb-2 mt-2">
                        <b>
                            <span>Upload Driver's License Document</span>
                        </b>
                    </p>
                    <span>Recommended format: .docx, .doc, .pdf, .jpg, .jpeg</span>
                </div>
            ),
            class:
                global.storeConfigration?.signup_page_changes?.value !== '1'
                    ? `col-12 col-sm-12 ${
                          global.storeConfigration?.display_register_doument_field?.value == 1
                              ? ''
                              : 'd-none'
                      }`
                    : 'd-none',
            folder: '',
            multiple: true,
            accept: [
                'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
                'application/msword',
                'application/pdf',
                'image/jpeg',
            ],
            compressImages: false,
        },
        {
            title: 'How Did You Find Us?',
            placeholder:
                global.storeConfigration?.signup_page_changes?.value !== '1'
                    ? ''
                    : 'How Did You Find Us?',
            type: global.storeConfigration?.signup_page_changes?.value !== '1' ? 'radio' : 'select',
            name: 'referral',
            int: 1,
            class:
                global.storeConfigration?.signup_page_changes?.value !== '1'
                    ? global.storeConfigration?.referral_value?.value == 1
                        ? 'col-md-12 hrFrm'
                        : 'd-none'
                    : 'col-md-12 hrFrm',
            customLabel: true,
            options:
                global.storeConfigration?.signup_page_changes?.value !== '1'
                    ? []
                    : referralOptions.map((option) => {
                          let busCategoryChanged = {}
                          busCategoryChanged.show = option.description
                          busCategoryChanged.value = option.id
                          return busCategoryChanged
                      }),
            item: referralOptions.map((option) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = option.description
                busCategoryChanged.value = option.id
                return busCategoryChanged
            }),
        },
        {
            label: 'Source' + '*',
            name: 'heard_from',
            type: 'text',
            placeholder: 'Enter source name',
            class: othersField ? 'col-md-12 hrFrm' : 'd-none',
            autoFocus: true,
        },
        {
            label: [
                'I agree to the',

                termsCondition ? (
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={termsCondition}
                        key="termsOfSerice"
                        style={{ textDecoration: 'underline' }}
                    >
                        Terms and Conditions
                    </a>
                ) : (
                    <Link
                        target="_blank"
                        rel="noreferrer"
                        to={'/termsOfService'}
                        key="termsOfSerice"
                        style={{ textDecoration: 'underline' }}
                    >
                        Terms and Conditions
                    </Link>
                ),
            ],
            name: 'termCheck',
            type: 'checkbox',
            class:
                global?.storeConfigration?.terms_con_must_reg?.value == 1 ||
                global?.storeConfigration?.remove_signup_terms?.value == 1
                    ? 'd-none'
                    : 'col-md-12',
        },
        {
            label: ['Send me product updates and news'],
            name: 'subscribe',
            type: 'checkbox',
            class:
                global.storeConfigration?.subscribe_code?.value != undefined &&
                global.storeConfigration?.subscribe_code?.value != ''
                    ? 'col-5'
                    : 'd-none',
        },
    )

    const registerInfo = {
        formik: formik,
        data,
    }

    const getLocationData = () => {
        var body = {
            countryCode: formik.values.country,
        }
        return axios
            .post(`${process.env.REACT_APP_URL}fetchLocation`, body)
            .then(function (response) {
                if (response.data.success) {
                    setStateList(response.data.state)
                    setCountryList(response.data.country)
                    console.log(response.data, 'responseCity')
                }
            })
            .catch(function (error) {
                console.error(error)
            })
    }

    useEffect(() => {
        getLocationData()
    }, [formik.values.country])

    useEffect(() => {
        getAlldynamic({
            site_id: global?.storeDetails?.site_id ? global.storeDetails.site_id : '',
            store_id: global?.storeDetails?.id ? global.storeDetails.id : '',
            type: 3,
        })
    }, [])

    useEffect(() => {
        if (global?.storeConfigration?.default_phone_code?.value) {
            var phone_code = global?.storeConfigration?.default_phone_code?.value
            if (
                formik.values.phone.length < phone_code.length ||
                !formik.values.phone.includes(phone_code)
            ) {
                formik.setFieldValue('phone', formik.values.phone_number_new)
            } else {
                formik.setFieldValue('phone_number_new', formik.values.phone)
            }
        }
    }, [formik.values.phone])

    useEffect(() => {
        if (formik) {
            if (dynamicproducts.length > 0) {
                if (dynamicproducts[0].form_json.includes('[{')) {
                    var data_add = data.slice(0, data.length - 2)
                    var json_temp = JSON.parse(dynamicproducts[0].form_json_temp)
                    var dynamic_fields = {}
                    JSON.parse(dynamicproducts[0].form_json).map((val, ind) => {
                        if (val.type == 'radio') {
                            // formik.values[val.name] = val.values.filter(
                            //     (v) => v.selected == 'true',
                            // )[0].value

                            var dynamic_selected_value = val.values.filter(
                                (v) => v.selected == 'true',
                            )
                            val.checked_need = 1
                            if (dynamic_selected_value.length > 0) {
                                dynamic_fields[val.name] = dynamic_selected_value[0].value
                            }
                        } else if (val.type == 'checkboxgroup') {
                            dynamic_fields[val.name] = []
                            val.values.map((kt) => {
                                if (kt.selected == 'true') {
                                    dynamic_fields[val.name].push(kt.value)
                                }
                            })
                        } else {
                            dynamic_fields[val.name] = json_temp[ind].value
                                ? json_temp[ind].value
                                : ''
                        }
                        data_add.push(val)
                    })
                    var dynamic_validation = Yup.object().shape(
                        json_temp.reduce((a, c) => {
                            let fieldSchema

                            if (c.required == 'true') {
                                if (c.type == 'text') {
                                    fieldSchema = Yup.string()
                                        .trim()
                                        .matches(
                                            /^[a-zA-Z\s]*$/g,
                                            'The special characters and numbers are not allowed!',
                                        )
                                        .max(
                                            parseInt(c.maxlength),
                                            `Maximum ${c.maxlength} characters`,
                                        )
                                        .required('Required')
                                }
                                if (c.type == 'textarea') {
                                    fieldSchema = Yup.string()
                                        .trim()
                                        .max(
                                            parseInt(c.maxlength),
                                            `Maximum ${c.maxlength} characters`,
                                        )
                                        .required('Required')
                                }
                                if (c.type == 'number') {
                                    fieldSchema = Yup.number()
                                        .typeError('Only numbers are allowed')
                                        .min(parseInt(c.min), `Minimum value is ${c.min}.`)
                                        .max(parseInt(c.max), `Maximum value is ${c.max}.`)
                                        .required('Required')
                                }
                                if (c.type == 'select') {
                                    fieldSchema = Yup.string().trim().required('Required')
                                }
                                if (c.type == 'checkbox-group') {
                                    fieldSchema = Yup.array()
                                        .min(1, 'Please select atleast one checkbox')
                                        .of(
                                            Yup.string().required(
                                                'Please select atleast one checkbox',
                                            ),
                                        )
                                        .required('Please select atleast one checkbox')
                                }
                                return { ...a, [c.name]: fieldSchema }
                            } else {
                                return a
                            }
                        }, {}),
                    )
                    setFields({
                        data: [...data_add, ...data.slice(data.length - 2)],
                        formik: formik,
                        dynamic_validation: Yup.object().shape({
                            ...validationArray.fields,
                            ...dynamic_validation.fields,
                        }),
                        dynamic_fields: dynamic_fields,
                    })
                    data = [...data_add, ...data.slice(data.length - 2)]
                } else {
                    setFields({ data: data, formik: formik })
                }
            } else {
                setFields({ formik: formik, data: data })
            }
        }
    }, [dynamicproducts, stateList, countryList, othersField])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'register') {
                if (responseStatus.status === 'success') {
                    if (formik.values.subscribe) {
                        const data = {
                            EMAIL: formik.values.email,
                        }
                        jsonp(
                            `//${
                                global.storeConfigration?.subscribe_code.value
                            }${queryString.stringify(data)}`,
                            { param: 'c' },
                            (err, data) => {
                                ////console.log('display', data.result)
                                if (err) {
                                    //console.log('display', err)
                                }
                                // if (data.result === 'success') {
                                //     setAlert(data.msg, 'success')
                                // }
                                if (data.result === 'error') {
                                    setAlert(data.msg, 'warning')
                                }
                            },
                        )
                    }
                    if (
                        searchParams.get('ref') &&
                        global.pluginConfiguration?.referral?.enable == 1
                    ) {
                        createReferral({
                            user_id: responseStatus.data.userid,
                            site_id: global?.storeDetails?.site_id,
                            email1: formik.values.email,
                            email2: atob(searchParams.getAll('ref')),
                            to_id: responseStatus.data.userid,
                            name: `${formik.values.first_name} ${formik.values.last_name}`,
                            source: 'Registered',
                        })
                    }
                    clearResponse()
                    if (searchParams.get('auction_id')) {
                        handleRedirectInternal(
                            history,
                            'login?auction_id=' + searchParams.get('auction_id'),
                        )
                    } else {
                        handleRedirectInternal(history, 'login')
                    }
                }
                setLoading(false)
            }

            if (responseStatus.from === 'verify_user_mail_phone') {
                if (
                    responseStatus.data?.messages?.length ||
                    responseStatus.data?.result?.length > 0
                ) {
                    if (!responseStatus.data?.messages?.length) {
                        responseStatus.data.messages = responseStatus.data.result
                    }
                    setOtppopup(true)
                    responseStatus.data.messages.map((val) => {
                        if (val.status_text == 'Verification code sent to your mobile number') {
                            var decipher = sha256.createDecipher(
                                'aes-256-ctr',
                                '!@#$Auction_Software_9089',
                            )
                            var dec = decipher.update(val.encrypt_sms_code, 'hex', 'utf8')
                            dec += decipher.final('utf8')

                            setMobileotp(dec)
                        }
                        if (val.status_text == 'Verification code sent to your email') {
                            var decipher = sha256.createDecipher(
                                'aes-256-ctr',
                                '!@#$Auction_Software_9089',
                            )
                            var dec = decipher.update(val.encrypt_sms_code, 'hex', 'utf8')
                            dec += decipher.final('utf8')

                            setEmailotp(dec)
                        }
                    })
                    // setAlert('Verification Code Sent Successfully!', 'success')
                }
                setLoading(false)
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (regpro && isAuthenticated) {
            var role = regpro.location.search.split('=')[1]
            formik.values.role = role
        }
        if (isAuthenticated) {
            logout()
        }
    }, [regpro])

    const call_register = () => {
        register(reg_req_data)
    }

    const call_verify_and_register = () => {
        if (mobileotp == verifyformik.values.smscode && emailotp == verifyformik.values.emailcode) {
            register(reg_req_data)
            setOtppopup(false)
        } else {
            if (mobileotp != verifyformik.values.smscode) {
                setAlert('Entered Sms Code Was Invalid', 'error')
            }
            if (emailotp != verifyformik.values.emailcode) {
                setAlert('Entered Email Code Was Invalid', 'error')
            }
        }
    }

    const verifyvalidationArray = Yup.object({
        smscode:
            global?.storeConfigration?.otp_verification_mobile_number?.value == 1
                ? Yup.string()
                      .trim()
                      .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'Special characters not allowed')
                      .max(4, 'Maximum 4 digits')
                      .required('Required')
                : '',
        emailcode:
            global?.storeConfigration?.otp_verification_email?.value == 1
                ? Yup.string()
                      .trim()
                      .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'Special characters not allowed')
                      .max(4, 'Maximum 4 digits')
                      .required('Required')
                : '',
    })

    const verifyformik = useFormik({
        initialValues: { smscode: '', emailcode: '' },
        validationSchema: verifyvalidationArray,
        onSubmit: async (values) => {
            call_verify_and_register()
        },
    })

    const verifypopup = [
        {
            label: 'SMS Code',
            name: 'smscode',
            type: 'number',
            placeholder: 'Enter your sms code',
            class:
                global?.storeConfigration?.signup_page_changes?.value == '1' &&
                global?.storeConfigration?.otp_verification_mobile_number?.value == 1
                    ? ''
                    : global?.storeConfigration?.otp_verification_mobile_number?.value == 1
                    ? ''
                    : 'd-none',
            formik: verifyformik,
        },
        {
            label: 'Email Code',
            name: 'emailcode',
            type: 'number',
            placeholder: 'Enter your email code',
            class: global?.storeConfigration?.otp_verification_email?.value == 1 ? '' : 'd-none',
            formik: verifyformik,
        },
    ]

    const setstatefrommap = () => {
        if (formik.values.tempAddress.split(',').length > 3) {
            let newAdd = formik.values.tempAddress.split(',')
            let addLength = newAdd.length
            //console.log('newAdd state', newAdd, addLength)
            let findValue = newAdd[addLength - 2].trim()
            let newState = stateList.findIndex(
                (e) => e.map_code_io == findValue || e.name == findValue,
            )
            if (newState != -1) {
                formik.setFieldValue('state', stateList[newState].name)
            } else {
                formik.setFieldValue('state', '')
            }

            let findcity = newAdd[addLength - 3].trim()
            formik.setFieldValue('city', findcity)
        } else {
            formik.setFieldValue('state', '')
            formik.setFieldValue('city', '')
        }
    }

    useEffect(() => {
        if (formik.values.tempAddress != '' && global.storeConfigration?.map_input?.value == '1') {
            if (formik.values.tempAddress.split(',').length > 3) {
                formik.values.address1 = formik.values.tempAddress
                let newAdd = formik.values.tempAddress.split(',')
                let addLength = newAdd.length
                //console.log('newAdd', newAdd, addLength)
                let findValue =
                    newAdd[addLength - 1].trim() == 'USA'
                        ? 'United States'
                        : newAdd[addLength - 1].trim() == 'UK'
                        ? 'United Kingdom'
                        : newAdd[addLength - 1].trim()
                let newCountry = countryList.findIndex((e) => e.name == findValue)
                if (newCountry > 0) {
                    if (formik.values.country != countryList[newCountry].location_id) {
                        formik.setFieldValue('country', countryList[newCountry].location_id)
                    } else {
                        setstatefrommap()
                    }
                } else {
                    formik.setFieldValue('country', '')
                }
            }
        }
    }, [formik.values.tempAddress])

    useEffect(() => {
        if (formik.values.tempAddress != '' && global.storeConfigration?.map_input?.value == '1') {
            setstatefrommap()
        }
    }, [stateList])

    useEffect(() => {
        if (formik.values.referral == 10) {
            setOtherField(true)
        } else {
            setOtherField(false)
        }
    }, [formik.values.referral])

    return (
        <>
            <form className="reg-step1" onSubmit={formik.handleSubmit}>
                {title && <h1>{title}</h1>}
                {subTitle && <h4>{subTitle}</h4>}
                <div className="row">
                    {Object.keys(fields).length > 0
                        ? mapData({ formik: formik, data: fields.data })
                        : mapData(registerInfo)}
                </div>
                <div className="text-center form-group">
                    {captcha && <Captch formik={formik} name="captch" />}
                    <PrimaryButton label="Submit" disabled={loading} type="submit" />
                    <div className="newAcc mt-3 text-center">
                        <p>
                            Already have an account ?{' '}
                            <Link
                                to="/login"
                                className={`${
                                    global.storeDetails.id === 41 && 'full-basket-login'
                                }`}
                            >
                                Login here
                            </Link>
                        </p>
                    </div>
                </div>
            </form>
            <Popup
                open={terms_condition}
                size="md"
                className="save-search-modal"
                modaltitle="Terms and Condition"
                handleClose={() => setTermscondition(false)}
            >
                {' '}
                <div className="px-5 py-4 stctPgWrpr">
                    {/* <h2>About Us</h2> */}
                    <span
                        dangerouslySetInnerHTML={{
                            __html: global?.storeDetails?.terms_condition
                                ? global.storeDetails.terms_condition
                                : '',
                        }}
                    ></span>
                </div>
                <div className="row">
                    <div className="col-4"></div>
                    <div className="col-3">
                        <PrimaryButton
                            onClick={(e) => call_register()}
                            btnSize="mt-3"
                            label="Agree & Submit"
                            type="submit"
                        />
                    </div>
                </div>
            </Popup>
            <Popup
                open={otppopup}
                size="xl"
                modalClass="save-search-modal"
                modaltitle="Verification"
                handleClose={() => setOtppopup(false)}
            >
                {' '}
                <div className="stctPgWrpr">
                    <div style={{ maxWidth: '400px', margin: 'auto' }}>{mapData(verifypopup)}</div>
                </div>
                <div style={{ maxWidth: 'fit-content', margin: 'auto' }}>
                    <div>
                        <PrimaryButton
                            onClick={verifyformik.handleSubmit}
                            btnSize="mt-3"
                            label="Submit"
                            type="submit"
                        />
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default SignupFunction
