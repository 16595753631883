import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import ClearIcon from '@material-ui/icons/Clear'
import ProductContext from '../../../Product/context/product/productContext'

import { useLocation } from 'react-router-dom'
const HeaderSearchComponent = (props) => {
    const productContext = useContext(ProductContext)
    const { productLocalState, setState: setProductState } = productContext
    const [productLocation, setProductLocation] = useState([])
    const [searchValues, setSearchValues] = useState({
        searchbar: '',
        est: [],
        rat: '',
        category: [],
    })

    const handleChange = (e) =>
        setSearchValues({ ...searchValues, [e.target.name]: e.target.value })

    const search1 = useLocation()
    let history = useHistory()
    const handleClear = (e) => {
        e.preventDefault()
        setSearchValues({ ...searchValues, searchbar: '' })
        history.push(`/search?q=`)
    }
    const handleClick = (e) => {
        e.preventDefault()
        history.push(
            `/search?q=${searchValues.searchbar}&est=${
                searchValues.est.length > 0 ? searchValues.est.join(',') : ''
            }&rat=${searchValues.rat}&cat=${
                searchValues.category.length > 0 ? searchValues.category.join(',') : ''
            }&view=0`,
        )
    }
    useEffect(() => {
        setProductLocation(productLocalState.globalLocations)
    }, [productLocalState.globalLocations])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        setSearchValues({
            ...searchValues,
            searchbar: params.get('q') ? params.get('q') : '',
            rat: params.get('rat') ? params.get('z') : '',
            est: params.get('est') ? params.get('est').split(',') : '',
            category: params.get('cat') ? params.get('cat').split(',') : '',
        })
    }, [])

    const handleLocationChange = (locationValue) => {
        localStorage.setItem('globalLocation', locationValue)
        setProductState({
            ...productLocalState,
            globalLocation: locationValue,
        })
        if (
            window.location.pathname.includes('product-buyer-auction') ||
            window.location.pathname.includes('/product/')
        ) {
            // handleRedirectInternal(history, '')
            history.replace('/')
        }
    }

    return (
        <form className="nav-standard-top-area-search">
            <div className="nav-standard-top-area-search-inner">
                <div className="custom-state-city">
                    <img src="/lots-images/loc.svg" alt="" />
                    <select
                        onChange={(e) => handleLocationChange(e.target.value)}
                        // onChange={(e) => setProductLocation(props.e.target.value)}
                        value={productLocalState.globalLocation}
                    >
                        {productLocation?.map((data, index) => (
                            <option value={data} key={index}>
                                {data}
                            </option>
                        ))}
                    </select>
                </div>
                <input
                    type="text"
                    onChange={handleChange}
                    name="searchbar"
                    value={searchValues.searchbar}
                    className="nav-standard-top-area-search-input open-sans"
                    placeholder="Search"
                />
                <button
                    onClick={(e) => {
                        handleClick(e)
                        if (props.onClose) {
                            props.onClose()
                        }
                    }}
                    className="lin lin-magnifier nav-standard-top-area-search-sumbit"
                >
                    <span className="material-icons">search</span>
                </button>
                {/* <button
                    onClick={handleClear}
                    className="lin lin-magnifier nav-standard-top-area-search-clear"
                >
                    <ClearIcon />
                </button> */}
            </div>
        </form>
    )
}

export default HeaderSearchComponent
