import React, { useContext, useState, useEffect, useRef } from 'react'
import BuyerContext from '../../Product/context/buyer/buyerContext'
import ProductContext from '../../Product/context/product/productContext'
import AuthContext from '../../Product/context/auth/authContext'

import NoRecordsFound from '../../Product/components/atoms/NoRecordsFound'
import SlidePanel from '../SlidePanel'
import { connect } from 'react-redux'
import { dateFormatFrontDay, handleRedirectInternal } from '../../Product/common/components'
import { Pagination } from '@material-ui/lab'
import { Link } from 'react-router-dom'
import Timer from '../../Product/common/timer'
import GridProduct from '../ProductCard/gridProduct'
import MultisellerSlider from '../SlidePanel/MultisellerSlider'
import { useHistory, useLocation } from 'react-router'
import Loaders from '../../Product/components/molecules/Loaders'
import { messageHandler } from '../../Product/common/socketHandler'
import { socket, socketAuction } from '../../Product/common/socket'
import AlertContext from '../../Product/context/alert/alertContext'
import LotProduct from '../ProductCard/lotProduct'
const AllBids = (props) => {
    const history = useHistory()
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const buyerContext = useContext(BuyerContext)
    const productContext = useContext(ProductContext)

    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const { user, isAuthenticated } = authContext
    const {
        buyer_allproducts,
        getAllBuyerProducts,
        product_view,
        getViewProduct,
        clearBuyerState,
    } = buyerContext
    const [isLoading, setIsLoading] = useState(true)
    const [Items, setData] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [totalItems, setTotalItem] = useState(0)
    const [toInvoice, settoInvoice] = useState(false)
    const [toInvoiceID, settoInvoiceID] = useState(false)
    const { responseStatus } = productContext
    const [search, setSearch] = useState({
        status: 'watchlist',
        sh_limit: 10,
        page: 1,
        product_type: props.page,
        ftpImage: props.need_socket_update ? false : true,
        need_auction_city: props.need_auction_city ? 1 : undefined,
        order: 1,
    })

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }
    const categoryTypes = {}
    global.allCategories.map((catgeory) => (categoryTypes[catgeory.id] = catgeory))
    useEffect(() => {
        setIsLoading(false)
        var fav_pro = [],
            total_pagecnt = 0
        if (buyer_allproducts.results.length > 0) {
            fav_pro = buyer_allproducts.results
            total_pagecnt = buyer_allproducts.total_pagecnt
            if (props.storeTheme == 'Liquidation_two') {
                fav_pro.map((data) => {
                    if (data?.extra_fields?.includes('}')) {
                        let parse = JSON.parse(data.extra_fields)
                        Object.keys(parse).map((val) => {
                            data[val] = parse[val]
                        })
                    }
                })
            }
            setData(fav_pro)
            setTotalItem(total_pagecnt)
        } else {
            setData(fav_pro)
            setTotalItem(total_pagecnt)
        }
    }, [buyer_allproducts])
    useEffect(() => {
        if (responseStatus && responseStatus !== '') {
            if (responseStatus === 'buynow_updated_successfull') {
                getAllBuyerProducts(search)
            } else if (responseStatus.from === 'removeWatchlist') {
                handleClose()
            }
        } else {
        }
        // console.log('Testng Testing================>', responseStatus)
    }, [responseStatus])

    useEffect(() => {
        if (isAuthenticated) {
            setIsLoading(true)
            getAllBuyerProducts(
                props.need_socket_update ? { ...search, ...{ need_bid_inc_io: 1 } } : search,
            )
        }
    }, [isAuthenticated, search])

    const handleClose = () => {
        params.delete('product')
        history.replace({
            pathname: location.pathname,
            search: params.toString(),
        })
        setSelectedProduct(null)
    }
    const handleClick = async (id) => {
        if (props.redirect_product_view) {
            handleRedirectInternal(history, `productView/${id}`)
        } else {
            setSelectedProduct(id)
            params.set('product', id)
            history.replace({
                pathname: location.pathname,
                search: params.toString(),
            })
        }
    }

    const getAllProducts = () => {
        getAllBuyerProducts(search)
            .then((res) => {
                if (res.success === 'yes' && params.get('product'))
                    getViewProduct({ product_id: Number(params.get('product')) })
            })
            .catch((err) => {
                // console.log(err, 'watchlist get products err')
            })
    }

    const viewProductRef = useRef(Items)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = Items
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(message, viewProductRef.current, userRef.current, setAlert, setData, type)
    }

    useEffect(() => {
        if (props.need_socket_update == 1) {
            socket.on('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.on('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.on('buyer_assurance', (data) => {
                handler(data, 'buyer_assurance')
            })

            socketAuction.on('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socketAuction.on('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            return () => {
                socket.off('realclosedupdates', (data) => {
                    handler(data, 'realclosedupdates')
                })
                socket.off('bidAddtime', (data) => {
                    handler(data, 'bidAddtime')
                })
                socket.off('buyer_assurance', (data) => {
                    handler(data, 'buyer_assurance')
                })

                socketAuction.off('realclosedupdates', (data) => {
                    handler(data, 'realclosedupdates')
                })
                socketAuction.off('bidAddtime', (data) => {
                    handler(data, 'bidAddtime')
                })
            }
        }
    }, [])

    return (
        <>
            {Items.length > 0 ? (
                isLoading ? (
                    <Loaders name="product_grid_view" isLoading={isLoading} loop={4} />
                ) : (
                    <>
                        <div className={`favGrid ${props.page}`}>
                            {Items.length !== 0 &&
                                Items.map((item, index) => {
                                    return (
                                        <div className="" key={`gl-${index}`}>
                                            {props.cardTheme === 'LotsAuction' ? (
                                                <LotProduct
                                                    data={item}
                                                    lotImages={item.lotImages}
                                                    link={`/gallery/product-view/${item.id}`}
                                                    handleClick={handleClick}
                                                    timerTheme={props.timerTheme}
                                                    type={props.page}
                                                    cardTheme={props.cardTheme}
                                                    noTimer={props.noTimer}
                                                    getAllProducts={props.getAllProducts}
                                                    setViewProduct={props.setViewProduct}
                                                    viewProduct={props.searchResult}
                                                    audio={props.audio}
                                                    fromComponent={props.fromComponent}
                                                    productViewLink={`/product/${item.id}`}
                                                    bidStatus={true}
                                                />
                                            ) : (
                                                <GridProduct
                                                    data={item}
                                                    bidStatus={true}
                                                    link={`/gallery/product-view/${item.id}`}
                                                    handleClick={handleClick}
                                                    timerTheme={props.timerTheme}
                                                    sliderTheme={props.sliderTheme}
                                                    cardTheme={props.cardTheme}
                                                    bidBtnLabel={props.bidBtnLabel}
                                                    getAllProducts={getAllProducts}
                                                    timerIcon={props.timerIcon || false}
                                                    need_end_date={props.need_end_date_from}
                                                    next_week_date={
                                                        props.date_now
                                                            ? props.next_week_date_from
                                                            : ''
                                                    }
                                                    className={props.className}
                                                    type={props.page}
                                                    productViewLink={
                                                        props.cardTheme === 'auctioneer'
                                                            ? `/productview/${item.id}/${
                                                                  item.auction
                                                                      ? 'auction'
                                                                      : 'buynow'
                                                              }/${
                                                                  (item?.custom_field_9?.includes(
                                                                      '}',
                                                                  )
                                                                      ? JSON.parse(
                                                                            item.custom_field_9,
                                                                        )
                                                                      : {}
                                                                  )?.game_theme
                                                                      ?.toLowerCase()
                                                                      ?.split(' ')
                                                                      ?.join('-') +
                                                                  '-' +
                                                                  item?.subcategory
                                                                      ?.split(',')
                                                                      ?.map(
                                                                          (val) =>
                                                                              categoryTypes[
                                                                                  val
                                                                              ]?.description?.toLowerCase() ??
                                                                              '',
                                                                      )
                                                                      ?.join('-')
                                                                      .split(' ')
                                                                      .join('-')
                                                              }`
                                                            : global.storeDetails?.theme === 18 ||
                                                              global.storeDetails?.theme === 19
                                                            ? `/product/${item.id}`
                                                            : props.productViewLink
                                                    }
                                                />
                                            )}
                                        </div>
                                    )
                                })}
                        </div>

                        <Pagination
                            count={Math.ceil(totalItems / search.sh_limit)}
                            page={search.page}
                            onChange={onHandlePage}
                        />
                    </>
                )
            ) : (
                <NoRecordsFound />
            )}
            {props.sliderTheme == 'multiseller' ? (
                <MultisellerSlider
                    storeTheme={props.storeTheme ? props.storeTheme : ''}
                    selectedLot={selectedProduct}
                    handleClose={handleClose}
                    type={props.type ? props.type : ''}
                />
            ) : (
                <SlidePanel type="right" selectedLot={selectedProduct} handleClose={handleClose} />
            )}
        </>
    )
}

export default AllBids
