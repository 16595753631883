import {
    ADD_PRODUCT,
    GET_EDIT_PRODUCT,
    GET_ALL_USERS,
    GET_NEW_AUCTION,
    GET_EDIT_AUCTION,
    GET_INVOICE,
    GET_INVOICE_NEW,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_SHIPPING_RATES,
    GET_ALL_SELLER,
    GET_ALL_SELLER_AUCTIONS,
    GET_ALL_SEARCH,
    GET_ALL_SIMILAR,
    GET_ALL_SEARCH_AUCTIONS,
    GET_ACTIVE_BID_PRODUCT,
    GET_WON_BID_PRODUCT,
    GET_LOST_BID_PRODUCT,
    UPDATE_PROJ_DYN,
    GET_LA_SELLERS,
    GET_ALL_PRODUCT_VIEWED,
    GET_ALL_LOT_AUCTIONS,
    GET_OVER_ALL_CATEGORIES,
    GET_SELLER_DASHBOARD,
    UPCOMING_LIST,
    GET_ALL_UNPAID_USERS,
    GET_POS_BUYNOW_PRODUCTS,
    GET_SUB_CATEGORIES,
    GET_ALL_BRAND_DETAILS,
    GET_ALL_DYNAMIC_FIELDS,
    GET_SIMILAR_PRODUCTS,
    GET_CAT_AND_SUBCAT,
    GET_SIMILAR_PRODUCTS_QTY,
    GET_ALL_CAT,
    GET_ALL_COUNT,
} from './productTypes'

export default (state, action) => {
    switch (action.type) {
        case ADD_PRODUCT:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ACTIVE_BID_PRODUCT:
            return {
                ...state,
                buyer_activeproducts: [action.payload],
            }
        case GET_WON_BID_PRODUCT:
            return {
                ...state,
                buyer_wonproducts: [action.payload],
            }
        case GET_LOST_BID_PRODUCT:
            return {
                ...state,
                buyer_lostproducts: [action.payload],
            }
        case GET_EDIT_PRODUCT:
            return {
                ...state,
                edit_product: action.payload,
            }
        case GET_ALL_USERS:
            return {
                ...state,
                all_active_users: action.payload,
            }
        case GET_INVOICE_NEW:
            return {
                ...state,
                group_invoice_details: action.payload,
            }
        case GET_SIMILAR_PRODUCTS:
            return {
                ...state,
                similar_products: action.payload,
            }
        case GET_SIMILAR_PRODUCTS_QTY:
            return {
                ...state,
                similar_products_qty: action.payload,
            }
        case GET_INVOICE:
            return {
                ...state,
                invoice_details: action.payload,
            }
        case GET_EDIT_AUCTION:
            return {
                ...state,
                edit_auction: action.payload,
            }
        case GET_LA_SELLERS:
            return {
                ...state,
                la_sellers: action.payload.la_sellers,
            }
        case GET_NEW_AUCTION:
            return {
                ...state,
                auction_lots: action.payload,
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_SELLER:
            return {
                ...state,
                seller_allproducts: action.payload,
            }
        case GET_ALL_SELLER_AUCTIONS:
            //console.log('in reducer ', action.payload)
            return {
                ...state,
                seller_allauctions: action.payload,
            }
        case GET_SHIPPING_RATES:
            return {
                ...state,
                shipping_rates: action.payload,
            }

        case GET_ALL_SIMILAR:
            return {
                ...state,
                search_allsimilarproducts: action.payload,
            }
        case GET_ALL_SEARCH:
            return {
                ...state,
                search_allproducts: action.payload,
            }
        case GET_ALL_LOT_AUCTIONS:
            return {
                ...state,
                search_allauctionproducts: action.payload,
            }
        case GET_ALL_SEARCH_AUCTIONS:
            return {
                ...state,
                search_allauctions: action.payload,
            }
        case UPDATE_PROJ_DYN:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case GET_ALL_PRODUCT_VIEWED:
            return {
                ...state,
                allProductsViewed: action.payload,
            }
        case GET_OVER_ALL_CATEGORIES:
            return {
                ...state,
                categories: action.payload,
            }
        case GET_SUB_CATEGORIES:
            return {
                ...state,
                sub_categories: action.payload,
            }
        case GET_SELLER_DASHBOARD:
            return {
                ...state,
                seller_dashboard_data: action.payload,
            }
        case UPCOMING_LIST:
            return {
                ...state,
                upcoming_list: action.payload,
            }
        case GET_ALL_UNPAID_USERS:
            return {
                ...state,
                all_unpaid_users: action.payload,
            }
        case GET_POS_BUYNOW_PRODUCTS:
            return {
                ...state,
                pos_buynow_products: action.payload,
            }
        case GET_ALL_BRAND_DETAILS:
            return {
                ...state,
                brandsproducts: action.payload,
            }
        case GET_ALL_DYNAMIC_FIELDS:
            return {
                ...state,
                dynamicproducts: action.payload,
            }
        case GET_CAT_AND_SUBCAT:
            return {
                ...state,
                auction_categories: {
                    auc_cat: action.payload.auc_cat,
                    auc_sub_cat: action.payload.auc_sub_cat,
                },
            }
        case GET_ALL_CAT:
            return {
                ...state,
                get_all_cat: action.payload,
            }
        case GET_ALL_COUNT:
            return {
                ...state,
                get_all_count: action.payload,
            }
        default:
            return state
    }
}
