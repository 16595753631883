import moment from 'moment-timezone'
import _ from 'lodash'
import * as Yup from 'yup'
const getTimeInterval = (ends, serverTimeSocket) => {
    let dateEnded = moment(ends).tz('America/New_York').valueOf()
    serverTimeSocket = moment(serverTimeSocket).tz('America/New_York').valueOf()
    let duration = ''
    if (serverTimeSocket < dateEnded) {
        duration = moment.duration(dateEnded - serverTimeSocket)
        let time = ''
        time += duration.years() ? duration.years() + ' Y ' : ''
        time += duration.months() ? duration.months() + ' M ' : ''
        time += duration.days() ? duration.days() + ' D ' : ''
        time += duration.hours() ? duration.hours() + 'H ' : ''
        time += duration.minutes() ? duration.minutes() + 'M ' : ''
        time += duration.seconds() + 'S'
        return time
    } else {
        return '0S'
    }
}

const getFilePath = (list, name, avatar_vc, isThumbNail = false) => {
    if (list.length !== 0) {
        let imageUrl = encodeURIComponent(name) //name.replace("#", "%23");
        let imageListUrl = []
        list.forEach((element) => {
            if (!element.includes('T')) {
                let position = element.indexOf('-')
                if (position !== -1) {
                    let fileName =
                        element.slice(0, position + 1) +
                        'T' +
                        element.slice(position + 1, element.length)
                    let index = list.indexOf(fileName)
                    if (index !== -1) {
                        imageListUrl.push({
                            original: `${process.env.REACT_APP_PRODUCT_IMAGE_URL}AS0145/${imageUrl}/${element}`,
                            thumbnail: `${process.env.REACT_APP_PRODUCT_IMAGE_URL}AS0145/${imageUrl}/${list[index]}`,
                        })
                    } else {
                        imageListUrl.push({
                            original: `${process.env.REACT_APP_PRODUCT_IMAGE_URL}AS0145/${imageUrl}/${element}`,
                            thumbnail: `${process.env.REACT_APP_PRODUCT_IMAGE_URL}AS0145/${imageUrl}/${element}`,
                        })
                    }
                }
            }
        })
        if (isThumbNail) {
            return imageListUrl[0]['thumbnail']
        }
        return imageListUrl
    } else {
        return isThumbNail
            ? `${global?.storeDetails?.logoValue}`
            : [
                  {
                      original: `${global?.storeDetails?.logoValue}`,
                      thumbnail: `${global?.storeDetails?.logoValue}`,
                  },
              ]
    }
}

const getFilePathSlide = (list, name, avatar_vc, isThumbNail = false) => {
    if (list.length !== 0) {
        let imageUrl = encodeURIComponent(name) //name.replace("#", "%23");
        let imageListUrl = []
        list.forEach((element) => {
            if (!element.includes('T')) {
                let position = element.indexOf('-')
                if (position !== -1) {
                    let fileName =
                        element.slice(0, position + 1) +
                        'T' +
                        element.slice(position + 1, element.length)
                    let index = list.indexOf(fileName)
                    if (index !== -1) {
                        imageListUrl.push({
                            original: `${process.env.REACT_APP_PRODUCT_IMAGE_URL}AS0145/${imageUrl}/${element}`,
                            thumbnail: `${process.env.REACT_APP_PRODUCT_IMAGE_URL}AS0145/${imageUrl}/${list[index]}`,
                        })
                    } else {
                        imageListUrl.push({
                            original: `${process.env.REACT_APP_PRODUCT_IMAGE_URL}AS0145/${imageUrl}/${element}`,
                            thumbnail: `${process.env.REACT_APP_PRODUCT_IMAGE_URL}AS0145/${imageUrl}/${element}`,
                        })
                    }
                }
            }
        })
        if (isThumbNail) {
            return imageListUrl[0]['thumbnail']
        }
        return imageListUrl
    } else if (avatar_vc != '') {
        //console.log('here in common function')
        return isThumbNail
            ? process.env.REACT_APP_PRODUCT_IMAGE_URL + avatar_vc
            : [
                  {
                      original: process.env.REACT_APP_PRODUCT_IMAGE_URL + avatar_vc,
                      thumbnail: `${process.env.REACT_APP_PRODUCT_IMAGE_URL}${
                          global.storeConfigration?.image_compression?.value == 1 ? 'thumb_' : ''
                      }${avatar_vc}`,
                  },
              ]
    } else {
        return isThumbNail
            ? `${global?.storeDetails?.logoValue}`
            : [
                  {
                      original: `${global?.storeDetails?.logoValue}`,
                      thumbnail: `${global?.storeDetails?.logoValue}`,
                  },
              ]
    }
}

const getValidProduct = (list) => {
    let position = _.findLastIndex(list, (item) => item.stock < item.qty)
    return position === -1
}
const getvaliditems = (list) => {
    // let position = _.findIndex(list, (item) => )
    var s = 0
    list.map((item) => {
        if (item.wprice > item.bprice) {
            s = 1
        }
    })
    return s
}

const percentage = (partialValue, totalValue) => (partialValue / 100) * totalValue

const getParameterByName = (name, url) => {
    if (!url) url = window.location.href
    name = name.replace(/[\[\]]/g, '\\$&')
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const formatDate = (date) => moment(date).format('L')
const formatDateTime = (date) => moment(date).format('L LT')

const swapCountry = (countries = []) => {
    if (global?.storeConfigration?.country_filter?.value) {
        var loc_id = global.storeConfigration.country_filter.value.split(',')
        var countries = countries.filter((val) => loc_id.includes(val.location_id.toString()))
    }
    const move = (from, to, ...arr) =>
        from === to ? arr : (arr.splice(to, 0, ...arr.splice(from, 1)), arr)
    var US_INDEX = countries.findIndex((val) => val.name === 'United States')
    return move(US_INDEX, 0, ...countries)
}

const imageError = () => `${global?.storeDetails?.logoValue}`

const getImageurl = (type, imgname) => {
    if (type === '0') {
        return `${global.images_url + 'product/'}${
            global.storeConfigration?.image_compression?.value == 1 ? 'thumb_' : ''
        }${imgname}`
    } else {
        return `${process.env.REACT_APP_PRODUCT_IMAGE_URL}${
            global.storeConfigration?.image_compression?.value == 1 ? 'thumb_' : ''
        }${imgname}`
    }
}

const getBase64 = (file, cb) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
        cb(reader.result)
    }
    reader.onerror = function (error) {
        //console.log('Error: ', error)
    }
}

const commonPaths = {
    mybids: '/dashboard/mybids',
    profile: '/dashboard/profile',
    transaction: '/dashboard/transactions',
    cards: '/dashboard/cards',
    favorites: '/dashboard/favorites',
    cart: '/cart',
    auctionCart: '/auctioncart',
    checkout: '/checkout/:type',
    invoice: '/invoice/:type',
    mylots: '/dashboard/mylots',
    pos: '/dashboard/pos',
    posCheckout: '/pos-checkout/:type',
}

const getunitsdetails = (val) => {
    if (val == 'lb') {
        return 'Lbs'
    } else if (val == 'oz') {
        return 'Ounce'
    } else if (val == 'g') {
        return 'Grams'
    } else if (val == 'kg') {
        return 'Kg'
    } else {
        return val
    }
}

const getdimensiondetails = (val) => {
    if (val == 'in') {
        return 'Inches'
    } else if (val == 'ft') {
        return 'Ft'
    } else if (val == 'g') {
        return 'Grams'
    } else if (val == 'kg') {
        return 'Cm'
    } else {
        return val
    }
}

const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ')

    let [hours, minutes] = time.split(':')

    if (hours === '12') {
        hours = '00'
    }

    if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12
    } else {
        if (parseInt(hours, 10) < 10) {
            hours = '0' + parseInt(hours, 10)
        }
    }

    return `${hours}:${minutes}`
}

const getYupObject = (fields = []) => {
    const yupObject = {}
    fields.map((data) => {
        if (data.validation) {
            yupObject[data.name] = data.validation
        }
    })
    console.log(yupObject)
    return Yup.object(yupObject)
}

const get_reatil_price_per = (Retail_PriceRetail_Price, Offer_Price) => {
    var Percentage_Saved = Retail_PriceRetail_Price - Offer_Price
    Percentage_Saved = Percentage_Saved / Retail_PriceRetail_Price
    Percentage_Saved = Percentage_Saved * 100
    return parseFloat(Percentage_Saved).toFixed(2)
}

const addWeekdays = (startDate, numberOfDays) => {
    var currentDate = new Date(startDate)
    var addedDays = 0

    while (addedDays < numberOfDays) {
        currentDate.setDate(currentDate.getDate() + 1)
        // Check if the current day is a weekday (Mon-Fri)
        if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
            addedDays++
        }
    }

    return currentDate
}

export {
    addWeekdays,
    getTimeInterval,
    getFilePath,
    percentage,
    getValidProduct,
    getParameterByName,
    getFilePathSlide,
    formatDate,
    formatDateTime,
    swapCountry,
    imageError,
    getImageurl,
    commonPaths,
    getvaliditems,
    getBase64,
    getunitsdetails,
    getdimensiondetails,
    convertTime12to24,
    getYupObject,
    get_reatil_price_per,
}
