/*eslint-disable*/
import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Layout from '../Layout'
import SearchAuctionList from '../../../Component/Search/SearchAuctionList'
import ProductContext from '../../../Product/context/product/productContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import './Auctions.css'
import { Button } from '@material-ui/core'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import LotsGridAuction from '../../../Component/ProductCard/lotsAuction'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import Loader from '../../../assets/loader'
import Loaders from '../../../Product/components/molecules/Loaders'
import { Pagination } from '@material-ui/lab'
import UserActivity from '../../../utils/CommonFunctionality/UserActivity'
import CategoryModal from '../../../Component/Categories/CategoryModal'

const Auctions = (props) => {
    const productContext = useContext(ProductContext)
    const { search_allauctions, getAllSearchAuctions, productLocalState } = productContext
    const [isLoading, setLoading] = useState(true)
    const [viewAuction, setViewAuction] = useState([])
    const [open2, setOpen2] = useState(false)
    const history = useHistory()
    const { search } = useLocation()
    const [searchbar] = useState(new URLSearchParams(window.location.search.substring(1)).get('q'))
    const type = new URLSearchParams(search)
    const [auctionView, setAuctionView] = useState(type.get('view') ? type.get('view') : 'Grid')
    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '12',
        orderby:
            global.storeConfigration?.auction_search_order?.value == 0
                ? undefined
                : global.storeConfigration?.auction_search_order?.value
                ? global.storeConfigration?.auction_search_order?.value
                : '1',
        searchbar: searchbar ? searchbar : '',
        auc_type: type.get('closed') ? '3' : '2',
        is_auction: 1,
        type: type.get('closed') ? 'closed' : '',
        need_lot_count: 1,
        lot_images: 1,
        //is_buynow: 1,
        auction_city:
            productLocalState.globalLocation != 'All' ? [productLocalState.globalLocation] : [],
    })
    useEffect(() => {
        if (!auctionSearch.auction_city.includes(productLocalState.globalLocation)) {
            setAuctionSearch({
                ...auctionSearch,
                auction_city:
                    productLocalState.globalLocation == 'All'
                        ? []
                        : [productLocalState.globalLocation],
            })
        }
    }, [productLocalState.globalLocation])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        if (auctionSearch.searchbar != params.get('q')) {
            setAuctionSearch({
                ...auctionSearch,
                searchbar: params.get('q') ? params.get('q') : '',
            })
        }
    }, [window.location.search])

    const [totalViewProduct, setTotalViewProduct] = useState(0)
    useEffect(() => {
        setLoading(true)
        getAllSearchAuctions(auctionSearch, 'auctionSearch')
    }, [auctionSearch])

    useEffect(() => {
        if (search_allauctions.from === 'auctionSearch') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
            setTotalViewProduct(search_allauctions.total_pagecnt)
            // setTimeout(() => {
            setLoading(false)
            // }, 1500)
        }
    }, [search_allauctions])

    const handleAuctionView = (types) => {
        let currentUrlParams = new URLSearchParams(window.location.search)
        currentUrlParams.set('view', types)
        history.push(history.location.pathname + '?' + currentUrlParams.toString())
    }
    useEffect(() => {
        setAuctionView(type.get('view') ? type.get('view') : 'Grid')
    }, [window.location.search])

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per page',
        },
        {
            value: '24',
            show: 'Results 24 per page',
        },
        {
            value: '36',
            show: 'Results 36 per page',
        },
    ]

    const sortShow = [
        {
            value: '',
            show: 'All Auctions',
        },
        {
            value: 1,
            show: 'Upcoming Auctions',
        },
        {
            value: 2,
            show: 'Live Auctions',
        },
        {
            value: 3,
            show: 'Closed Auctions',
        },
    ]

    const handleCategory = () => {
        setOpen2(true)
    }

    return (
        <Layout props={props}>
            <UserActivity page="auction_search" />
            <div className="auctions-header">
                <div className="container auctionsSearch mh-auto">
                    <div className="d-flex justify-content-between align-items-center flex-wrap  auctionSearchCnt">
                        <h2 className="auctionListTitle">Auctions</h2>
                        <div className="auctionsMiscFilter">
                            <div className="gridListToggle ml-auto">
                                <Button
                                    className={auctionView === 'Grid' ? 'active' : ''}
                                    onClick={() => handleAuctionView('Grid')}
                                >
                                    <span className="material-icons">apps</span>
                                </Button>
                                <Button
                                    className={auctionView === 'List' ? 'active' : ''}
                                    onClick={() => handleAuctionView('List')}
                                >
                                    <span className="material-icons">view_list</span>
                                </Button>
                            </div>
                            <CustomSelect
                                label=""
                                name="resultsPerPage"
                                selectType="noBorder"
                                value={auctionSearch.sh_limit}
                                size="small"
                                onChange={(event, editor) => {
                                    setAuctionSearch({
                                        ...auctionSearch,
                                        sh_limit: event.target.value,
                                    })
                                }}
                            >
                                {pageOptions.map((opt, optindex) => (
                                    <option value={opt.value}>{opt.show}</option>
                                ))}
                            </CustomSelect>
                            <CustomSelect
                                label=""
                                value={auctionSearch.auc_type}
                                size="small"
                                selectType="noBorder"
                                name="auc_type"
                                // shrink={search.orderby !== '' ? true : false}
                                onChange={(event, editor) => {
                                    if (event.target.value == '3') {
                                        setAuctionSearch({
                                            ...auctionSearch,
                                            auc_type: event.target.value,
                                            type: 'closed',
                                        })
                                    } else if (event.target.value == '') {
                                        setAuctionSearch({
                                            ...auctionSearch,
                                            auc_type: event.target.value,
                                            type: 'all',
                                            orderby: 1,
                                        })
                                    } else {
                                        setAuctionSearch({
                                            ...auctionSearch,
                                            auc_type: event.target.value,
                                            type: '',
                                        })
                                    }
                                }}
                            >
                                {sortShow.map((opt, optindex) => (
                                    <option value={opt.value}>{opt.show}</option>
                                ))}
                            </CustomSelect>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container auctionsSearch">
                <>
                    <>
                        {isLoading ? (
                            <div className="text-center">
                                <Loaders name="product_grid_view" isLoading={isLoading} loop={4} />
                            </div>
                        ) : viewAuction.length > 0 ? (
                            <div className={`searchResults ${auctionView}`}>
                                {viewAuction.map((data, index) =>
                                    auctionView === 'Grid' ? (
                                        <LotsGridAuction
                                            key={index}
                                            data={data}
                                            details={`${
                                                data.con_check
                                                    ? 'liveauctionview'
                                                    : 'search/product-buyer-auction'
                                            }/${data.id}?cat=1&rat=1&est=1`}
                                            // details={`search/product-buyer-auction/${data.id}`}
                                            timerTheme="multiseller"
                                            theme="multiseller"
                                            openCategory={handleCategory}
                                        />
                                    ) : (
                                        <MultisellerListCard
                                            key={index}
                                            data={data}
                                            fromSearch={true}
                                            allsearchitems={viewAuction}
                                            // lotCount={data.lotCount ? data.lotCount : 0}
                                            details={`${
                                                data.con_check
                                                    ? 'liveauctionview'
                                                    : 'search/product-buyer-auction'
                                            }/${data.id}?cat=1&rat=1&est=1`}
                                            currencyType=""
                                            noTimer={true}
                                            card_theme="lotsauction"
                                            openCategory={handleCategory}
                                            timerTheme=""
                                        />
                                    ),
                                )}
                            </div>
                        ) : (
                            <NoRecordsFound />
                        )}
                    </>
                </>
                {totalViewProduct > auctionSearch.sh_limit && (
                    <Pagination
                        count={Math.ceil(totalViewProduct / auctionSearch.sh_limit)}
                        page={auctionSearch.page}
                        onChange={(e, v) => setAuctionSearch({ ...auctionSearch, page: v })}
                    />
                )}
            </div>
            <CategoryModal open={open2} handleClose={() => setOpen2(false)} />
        </Layout>
    )
}

export default Auctions
