import React from 'react'
import Scheduler from './index'
import CustomDialog from '../../../Product/components/organisms/Dialog'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button } from '@material-ui/core'
import { mapData } from '../../../Product/common/components'
import { useState } from 'react'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { useContext } from 'react'
import authContext from '../../../Product/context/auth/authContext'
import alertContext from '../../../Product/context/alert/alertContext'
import schedulePickupContext from '../../../Product/context/plugin/schedulePickup/schedulePickupContext'
import { useEffect } from 'react'
import moment from 'moment'
import MultiCheckIn from './MultiCheckIn'

const MultiPickup = ({
    invoice,
    pickupData,
    auctionData,
    sellerData,
    trigger,
    setTrigger,
    sch_type,
}) => {
    const [scheduleStep, setScheduleStep] = useState(0)
    const [scheduleModal, setScheduleModal] = useState(false)
    const [scheduleModal1, setScheduleModal1] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)
    const [bookingDates, setBookingDates] = useState({
        start_date: '',
        end_date: '',
    })
    const [allLocations, setAllLocations] = useState([])
    const [loading, setLoading] = useState()
    const { user, loadUser } = useContext(authContext)
    const { setAlert } = useContext(alertContext)
    const {
        getAllTimings,
        updateDefaultTimings,
        addSchedule,
        responseStatus,
        all_timings,
        clearResponse,
    } = useContext(schedulePickupContext)
    const pickupValidationSchema = Yup.object().shape({
        name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z ]*$/g, 'Special characters are not allowed')
            .max(20, '20 characters max')
            .required('Required'),
        email: Yup.string()
            .email('Invalid email format')
            .max(250, '250 characters max')
            .required('Required'),
        phone: Yup.string()
            .trim()
            .matches(/^[+]?[0-9]+$/, 'Only numbers are allowed')
            .min(10, 'Invalid!')
            .max(15, 'Maximum 14 characters')
            .required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            name: `${user?.first_name} ${user?.last_name}`,
            email: user?.email,
            phone: user?.phone,
            location_id: allLocations[0]?.id,
            location_name: '',
            curbside: '',
        },
        validationSchema: scheduleStep == 1 && pickupValidationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            if (scheduleStep == 0) {
                if (!values.location_id) {
                    return setAlert('Kindly Select Any One Location!', 'error')
                }
                updateDefaultTimings(
                    allLocations?.find((val) => val.id == values.location_id)?.range_key,
                )
            }
            if (scheduleStep != 2) {
                return handleScheduleNext()
            }
            if (!selectedTime) {
                return setAlert('Kindly Select Time!', 'error')
            }

            const body = {
                name: values.name,
                email: values.email,
                phone: values.phone,
                site_id: invoice?.site_id,
                user_id: user?.id,
                invoice_id: invoice?.common_invoice,
                base_url: global?.storeDetails?.dmap_url?.includes('/search')
                    ? global?.storeDetails?.dmap_url?.replace('/search', '')
                    : global?.storeDetails?.dmap_url,
                booking_start_date: moment(`${selectedDate} ${selectedTime}`).format(
                    'MM/DD/YYYY h:mm A',
                ),
                booking_end_date: moment(`${selectedDate} ${selectedTime}`)
                    .add(
                        allLocations?.find((val) => val.id == values.location_id)?.range_key[0]
                            ?.pickup_minutes != 0
                            ? allLocations?.find((val) => val.id == values.location_id)
                                  ?.range_key[0]?.pickup_minutes
                            : 5,
                        'minutes',
                    )
                    .format('MM/DD/YYYY h:mm A'),
                seller_name: sellerData?.seller_name,
                seller_email: sellerData?.email,
                location_id: values.location_id,
                location_name: allLocations?.find((val) => val.id == values.location_id)
                    ?.range_key[0]?.business_name,
                curbside: values.curbside,
                total_count: allLocations?.find((val) => val.id == values.location_id)?.range_key[0]
                    ?.pickup_limit,
            }
            setLoading(true)
            addSchedule(body)
        },
    })

    const pickupInfo = [
        {
            label: 'Name',
            name: 'name',
            type: 'text',
            placeholder: 'Enter your name',
            class: 'col-12',
            formik: formik,
        },
        {
            label: 'Email address',
            name: 'email',
            type: 'email',
            placeholder: 'Enter your email address',
            class: 'col-12',
            formik: formik,
        },
        {
            label: 'Phone number',
            name: 'phone',
            type: 'text',
            placeholder: 'Enter your phone number',
            class: 'col-12',
            formik: formik,
        },
    ]

    const handleScheduleBack = () => {
        if (scheduleStep > 0) {
            setScheduleStep(scheduleStep - 1)
        }
    }

    const handleScheduleNext = () => {
        if (scheduleStep < 3) {
            if (scheduleStep == 2) {
                return setScheduleStep(scheduleStep + 2)
            }
            setScheduleStep(scheduleStep + 1)
        }
    }

    const manageScheduleModal = () => {
        if (user?.allow_schedule == 1) {
            setScheduleModal1(!scheduleModal1)
        } else {
            setScheduleModal(!scheduleModal)
            setScheduleStep(0)
            formik.resetForm()
            formik.setTouched({})
        }
    }
    useEffect(() => {
        if (invoice?.site_id && scheduleStep != 2) {
            getAllTimings({
                site_id: invoice?.site_id,
                random_id: '',
                id: '',
            })
        }
    }, [invoice])

    useEffect(() => {
        if (sch_type == 1) {
            loadUser()
        }
    }, [sch_type])

    useEffect(() => {
        setAllLocations(all_timings.all_locations)
    }, [all_timings.all_locations])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'addSchedule') {
                setLoading(false)
                if (responseStatus.status === 'success') {
                    handleScheduleNext()
                    setTimeout(() => {
                        setTrigger(!trigger)
                        manageScheduleModal()
                    }, 2000)
                }
                if (responseStatus.status === 'error') {
                    setAlert(responseStatus.message, 'error')
                }
            }
            clearResponse()
        }
    }, [responseStatus])
    return (
        <div>
            {sch_type == 2 && (
                <MultiCheckIn
                    pickupData={invoice}
                    setTrigger={setTrigger}
                    trigger={trigger}
                    curbside={
                        all_timings?.all_locations?.find(
                            (val) => val.id == invoice?.schedule_location_id,
                        )?.range_key[0]?.curbside
                    }
                />
            )}
            {sch_type == 1 && (
                <PrimaryButton btnSize="schedulePickupBtn mt-3" onClick={manageScheduleModal}>
                    <span className="material-icons mr-2">local_shipping</span>
                    Schedule Pickup
                </PrimaryButton>
            )}
            <CustomDialog
                title={'Schedule Pickup'}
                open={scheduleModal}
                function={manageScheduleModal}
                className="scheduleModal"
                closeBtn={true}
                maxWidth="md"
            >
                <div className="scheduleModalCnt">
                    {scheduleStep === 0 ? (
                        <div className="smDetails">
                            <div className="smTop">
                                <h5 className="smTitle">Choose Your Pickup Location</h5>
                            </div>
                            <div className="smMain">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        <div className="container col-12">
                                            {(allLocations.find(
                                                (val) => val.id == auctionData?.location_id,
                                            )
                                                ? [
                                                      allLocations.find(
                                                          (val) =>
                                                              val.id == auctionData?.location_id,
                                                      ),
                                                  ]
                                                : allLocations
                                            )?.map((val) => (
                                                <div>
                                                    <div className="card-input border my-2 mx-0 p-3 d-flex align-items-start justify-content-between flex-wrap">
                                                        <div className="card-body border-0 p-0">
                                                            {val.range_key[0]?.business_name}
                                                            <address>
                                                                {
                                                                    val.range_key[0]
                                                                        ?.business_address1
                                                                }{' '}
                                                                {
                                                                    val.range_key[0]
                                                                        ?.business_address2
                                                                }
                                                                <br />
                                                                {
                                                                    val.range_key[0]?.business_state
                                                                }, {val.range_key[0]?.business_city}
                                                                , {val.range_key[0]?.business_zip}
                                                                <br />
                                                                {val.range_key[0]?.business_country}
                                                            </address>
                                                        </div>
                                                        <div className="cardFoot">
                                                            <PrimaryButton
                                                                label="Schedule an appointment"
                                                                type="submit"
                                                                onClick={() =>
                                                                    formik.setFieldValue(
                                                                        'location_id',
                                                                        val.id,
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : scheduleStep === 1 ? (
                        <div className="smDetails">
                            <div className="smTop d-flex align-items-center justify-content-start">
                                <Button className="backBtn" onClick={handleScheduleBack}>
                                    <span className="material-icons">arrow_back</span>
                                </Button>
                                <h5 className="smTitle m-0">Enter Your Details</h5>
                            </div>
                            <div className="smMain">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        {Object.values(mapData(pickupInfo))}
                                        <div className="col-12">
                                            <PrimaryButton label="Next" type="submit" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : scheduleStep === 2 ? (
                        <div className="smDate">
                            <div className="smTop d-flex align-items-center">
                                <Button className="backBtn mr-2" onClick={handleScheduleBack}>
                                    <span className="material-icons">arrow_back</span>
                                </Button>
                                <h5 className="smTitle m-0">Select Date & Time</h5>
                            </div>{' '}
                            <div className="smMain">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <Scheduler
                                                selectedDate={selectedDate}
                                                setSelectedDate={setSelectedDate}
                                                selectedTime={selectedTime}
                                                setSelectedTime={setSelectedTime}
                                                pickupData={pickupData}
                                                invoice={invoice}
                                                location_id={formik.values.location_id}
                                                bookingDates={bookingDates}
                                                setBookingDates={setBookingDates}
                                            />
                                        </div>
                                        {selectedDate ? (
                                            <div className="col-12">
                                                <PrimaryButton
                                                    label={loading ? 'Loading' : 'Schedule Pickup'}
                                                    type="submit"
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : scheduleStep === 4 ? (
                        <div className="smDate">
                            <div className="smMain booked text-center">
                                <span
                                    className="material-icons-outlined"
                                    style={{ fontSize: '55px' }}
                                >
                                    download_done
                                </span>
                                <h4>Booking Confirmed!</h4>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </CustomDialog>
            <CustomDialog
                title={'Schedule Pickup'}
                open={scheduleModal1}
                function={manageScheduleModal}
                className="scheduleModal blockedModal"
                maxWidth="md"
            >
                <div className="scheduleModalCnt">
                    <div className="smDetails">
                        <div className="smTop">
                            <h5 className="smTitle mb-4">
                                Your Schedule Pickup feature is blocked. Contact Admin for further
                                info.
                            </h5>
                        </div>
                        <div className="smMain">
                            <div className="cardFoot">
                                <PrimaryButton
                                    label="Close"
                                    type="submit"
                                    onClick={() => setScheduleModal1(!scheduleModal1)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </CustomDialog>
        </div>
    )
}

export default MultiPickup
