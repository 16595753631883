import React, { useEffect, useState, useContext, useRef } from 'react'
import _, { values } from 'lodash'
import {
    percentage,
    formatDateTime,
    formatDate,
    get_reatil_price_per,
} from '../../../utils/commonFunctions'
import AuthContext from '../../../Product/context/auth/authContext'
import {
    handleRedirectInternal,
    mapData,
    dateFormatFunction,
    dateTimeFormatFunction,
} from '../../../Product/common/components'
import ProductContext from '../../../Product/context/product/productContext'
import PluginContext from '../../../Product/context/plugin/pluginContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import PayContext from '../../../Product/context/payment/payContext'
import { useParams, useHistory } from 'react-router-dom'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { Button } from '@material-ui/core'
import Popup from '../../../Product/components/organisms/Popup'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { useFormik } from 'formik'
import { currencyFormat } from '../../../Product/common/components'
import * as Yup from 'yup'
import Tooltip from '@material-ui/core/Tooltip'
import ShippingpaymentComponent from './shipping _amount_payment'
import { useTranslation } from 'react-i18next'
import ReactHtmlParser from 'react-html-parser'
import ScheduleLocalPickup from '../ScheduleLocalPickup'
import UserActivity from '../UserActivity'
import Pickup from '../Scheduler/Pickup'
import NewSchedulePickup from '../Scheduler/NewSchedulePickup'
import InvoiceTerms from '../../../Component/AuctionTerms/invoiceTerms'

const Invoice = (props) => {
    const authContext = useContext(AuthContext)
    const pluginContext = useContext(PluginContext)
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const payContext = useContext(PayContext)
    const { setAlert } = alertContext
    const { t } = useTranslation()
    const {
        make_item_return_request,
        responseStatus: responseStatusPlugin,
        clearResponse,
        requestdiscount,
        requestTrackingNumber,
    } = pluginContext
    const [data, setData] = useState([])
    const [userData, setUserData] = useState({})
    const [discount_amount_tot, setDiscountAmount] = useState(0)
    const [summary, setSummary] = useState({})
    const [returnitem, setReturn] = useState(false)
    const [return_id, setReturnid] = useState([])
    const [returbbtn, setReturnbtn] = useState(false)
    const [sellerData, setSellerData] = useState({})
    const [auctionData, setAuctionData] = useState({})
    const [schedulePickup, setSchedulePickup] = useState({})
    const [payRecords, setPayRecords] = useState({})
    const [payRecordsmultiple, setPayRecordsmultiple] = useState([])
    const [loading, setLoading] = useState(false)
    const id = new URLSearchParams(window.location.search.substring(1)).get('id')
    const buyer_id = new URLSearchParams(window.location.search.substring(1)).get('bid')
    const map_query = new URLSearchParams()
    const { type } = useParams()
    const history = useHistory()
    const [open, setOpen] = useState(false)
    const [trigger, setTrigger] = useState(false)
    const [openinfo, setOpeninfo] = useState(false)
    const { isAuthenticated, user } = authContext
    const { getInvoiceNew, group_invoice_details, Edit_invoice, responseStatus } = productContext
    const { successAfterPayAuctionCalculation, responseStatus: responseStatusPay } = payContext
    const [btnLoading, setBtnLoading] = useState(false)
    const inputRef = useRef(null)
    const [user_role, setUserRole] = useState(0)
    const [paypalBtnLoading, setPaypalBtnLoading] = useState(false)
    const [savings, setSavings] = useState(0)
    useEffect(() => {
        if (isAuthenticated) {
            if (!id) {
                return handleRedirectInternal(history, 'dashboard/mybids')
            }
            const body = {
                id,
                auction_io: 1,
            }
            if (buyer_id) {
                body.user_id = buyer_id
            }
            if (type === 'buynow') {
                body.hasShipping = 0
                body.isAuction = 0
            }
            getInvoiceNew(body)
        }
    }, [isAuthenticated, trigger])

    useEffect(() => {
        if (group_invoice_details.product_details) {
            setData(group_invoice_details.product_details)
            setUserData(group_invoice_details.buyer_details)
            setSellerData(group_invoice_details.seller_details)
            setAuctionData(group_invoice_details.auction_details)
            setSchedulePickup(group_invoice_details.schedule_pickup)
            setPayRecords(group_invoice_details.pay_records[0])
            var in_arr = []
            if (global?.pluginConfiguration?.partial_payment?.enable) {
                in_arr = group_invoice_details.pay_records
                in_arr = in_arr.filter((obj, index) => {
                    return index === in_arr.findIndex((o) => o.transid == obj.transid)
                })
            }
            setPayRecordsmultiple(in_arr.reverse())
            var total_inv = {
                buyerPremium: 0,
                buyerPremiumPercentage: 0,
                shippingPercentage: 0,
                shippingCost: 0,
                amount: 0,
                salesTax: 0,
                salesTaxPercentage: 0,
                transactionFee: 0,
                total_invoice: 0,
                local_pick: 0,
                shipping: 0,
                additional_amount_2: 0,
                additional_payments: 0,
                lineItemFee: 0,
                transfer_fee: 0,
                buyerassurancevalue: 0,
                paid_date: new Date(),
                dis_count_amount: 0,
            }
            var p_id = []
            group_invoice_details.product_details.map((value) => {
                total_inv.buyerPremium +=
                    parseFloat(value.buypremium_amount) + parseFloat(value.selling_fee)
                total_inv.buyerPremiumPercentage =
                    parseFloat(value.buypremium) + parseFloat(value.featured_fee)
                total_inv.shippingPercentage = value.additional_amount_1
                total_inv.shippingCost += value.shipping_info
                    ? parseFloat(value.shippingcost)
                    : parseFloat(value.shipping_amount)
                total_inv.amount += parseFloat(value.amount)
                total_inv.additional_amount_2 += parseFloat(value.additional_amount_2)
                total_inv.additional_amount_3 += parseFloat(value.additional_amount_3)
                total_inv.salesTaxPercentage =
                    parseInt(value.paid) == 0
                        ? parseFloat(value.sales_tax)
                        : parseFloat(value.salestax_amt)
                total_inv.salesTax += parseFloat(value.sales_tax_amount)
                total_inv.transactionFee += parseFloat(value.transaction_fee)
                total_inv.lineItemFee += parseFloat(value.line_item_fee)
                total_inv.transfer_fee += parseFloat(value.transfer_loc_fee)
                total_inv.additional_payments = parseFloat(value.additional_expense)
                total_inv.buyerassurancevalue += parseFloat(value.buyerassurancevalue)
                total_inv.paid_date = value.paid_date
                total_inv.dis_count_amount = value.discount_amount
                if (value.shipping_info && type === 'buynow' && parseInt(value.paid) == 0) {
                    total_inv.total_invoice +=
                        parseFloat(value.shippingcost) + parseFloat(value.total_invoice)
                    total_inv.new_transaction_fee =
                        parseFloat(
                            parseFloat((parseFloat(value.shippingcost) * 3.1) / 100).toFixed(2),
                        ) + 0.3
                } else {
                    total_inv.total_invoice += parseFloat(value.total_invoice)
                }
                p_id.push(value.project_id)
            })
            var local_pick = group_invoice_details.product_details.find(
                (val) => val.local_pick == 1,
            )
            var shipping = group_invoice_details.product_details.find(
                (val) => val.hasshipping == 1 && val.paddle_notes == 'no',
            )
            total_inv.local_pick = local_pick ? 1 : 0
            total_inv.shipping = shipping ? 1 : 0
            setSummary(total_inv)
            if (global?.pluginConfiguration?.return_assurance?.enable) {
                var return_date = new Date().setDate(
                    new Date(total_inv.paid_date).getDate() +
                        parseInt(
                            global.storeConfigration?.return_value_days?.value
                                ? global.storeConfigration.return_value_days.value
                                : 1,
                        ),
                )
                if (
                    new Date() <= new Date(return_date) &&
                    parseFloat(total_inv.buyerassurancevalue) > 0
                ) {
                    setReturn(true)
                }
            }
            if (group_invoice_details.product_details[0].paid == 0) {
                formik.values.amount = group_invoice_details.product_details[0].amount
                formik.values.sales_tax_amount =
                    group_invoice_details.product_details[0].sales_tax_percent
                formik.values.shipping_amount = group_invoice_details.product_details[0].is_digital
                    ? group_invoice_details.product_details[0].is_digital
                    : group_invoice_details.product_details[0].shipping_amount
                formik.values.buypremium_amount =
                    group_invoice_details.product_details[0].aio_buy_percentage
                formik.values.transaction_fee =
                    group_invoice_details.product_details[0].transaction_fee
                formik.values.invoiceId = id
            }

            if (global.pluginConfiguration?.coupon_code?.enable) {
                const body = {
                    inputcpn: '',
                    storeId: global?.storeDetails?.id,
                    pid: p_id,
                }
                requestdiscount(body)
            }
        }
    }, [group_invoice_details.product_details])

    useEffect(() => {
        let savingsSum = 0
        data.map((value) => {
            savingsSum =
                parseFloat(value.mprice) -
                (parseFloat(value.wprice) +
                    parseFloat(value.buypremium_amount) +
                    parseFloat(value.line_item_fee)) +
                parseFloat(savingsSum)
        })

        setSavings(savingsSum)
    }, [data])

    useEffect(() => {
        if (responseStatusPlugin) {
            if (responseStatusPlugin.from === 'requestDiscount') {
                if (responseStatusPlugin.status === 'success') {
                    var prdt = data
                    var req_data_arr = responseStatusPlugin.message
                    var total_discount_amount = 0
                    prdt.map((val) => {
                        var req_data_s_arr = req_data_arr.filter(
                            (val1) => val1.project_id == val.project_id,
                        )

                        if (req_data_s_arr.length > 0) {
                            var req_data = req_data_s_arr[0]
                            if (req_data.discount_type == 'percentage') {
                                if (
                                    parseInt(val.user_id) ==
                                    parseInt(
                                        global?.storeDetails?.user_id
                                            ? global.storeDetails.user_id
                                            : 0,
                                    )
                                ) {
                                    var discount_amount = parseFloat(
                                        parseFloat(
                                            val.amount *
                                                parseFloat(req_data.percentage_value / 100),
                                        ),
                                    ).toFixed(2)
                                } else {
                                    if (parseFloat(val.multiseller_commission) > 0) {
                                        val.custom_seller_commission = val.multiseller_commission
                                    } else {
                                        val.custom_seller_commission = global?.storeConfigration
                                            ?.commission_fee?.value
                                            ? global.storeConfigration.commission_fee.value
                                            : 0
                                    }

                                    var commision_fee_amount = val.custom_seller_commission
                                    var discount_amount = parseFloat(
                                        parseFloat(
                                            commision_fee_amount *
                                                parseFloat(req_data.percentage_value / 100),
                                        ),
                                    ).toFixed(2)
                                }
                            } else if (req_data.discount_type == 'fixedamount') {
                                var discount_amount = req_data.fixedamount_value
                            }
                            total_discount_amount = parseFloat(
                                parseFloat(total_discount_amount) + parseFloat(discount_amount),
                            ).toFixed(2)
                            console.log('total discount amount', total_discount_amount)
                        }
                    })
                    setData([...data])
                    setDiscountAmount(total_discount_amount)
                }
                clearResponse()
            }
            if (responseStatusPlugin.from === 'requestTrackingNumber') {
                if (responseStatusPlugin.status === 'success') {
                    setAlert(responseStatusPlugin.message, 'success')
                    setTrigger(!trigger)
                }
            }
            if (responseStatusPlugin.from === 'make_item_return_request') {
                if (responseStatusPlugin.status == 'success') {
                    setReturn(false)
                    setOpeninfo(true)
                }
                setAlert(responseStatusPlugin.message, responseStatusPlugin.status)
            }
        }
    }, [responseStatusPlugin])

    useEffect(() => {
        if (responseStatusPay) {
            if (responseStatusPay.from === 'successAfterPayAuctionCalculation') {
                setPaypalBtnLoading(false)
                if (responseStatusPay.status === 'success') {
                    setAlert(responseStatusPay.message, responseStatusPay.status)
                    const body = {
                        id,
                    }
                    if (buyer_id) {
                        body.user_id = buyer_id
                        body.auction_io = 1
                    }
                    if (type === 'buynow') {
                        body.hasShipping = 0
                        body.isAuction = 0
                    }
                    getInvoiceNew(body)
                }
            }
        }
    }, [responseStatusPay])
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'edit_invoice') {
                setBtnLoading(false)
                setAlert(responseStatus.message, responseStatus.status)
                if (responseStatus.status === 'success') {
                    setOpen(false)
                    if (user_role == 1) {
                        const body = {
                            id,
                        }
                        if (buyer_id) {
                            body.user_id = buyer_id
                            body.auction_io = 1
                        }
                        if (type === 'buynow') {
                            body.hasShipping = 0
                            body.isAuction = 0
                        }
                        getInvoiceNew(body)
                    } else {
                        if (type === 'buynow') {
                            handleRedirectInternal(history, 'dashboard/mybids?s=3')
                        } else {
                            handleRedirectInternal(history, 'dashboard/mybids?s=1')
                        }
                    }
                }
            }
            if (responseStatus.from === 'updatelocalpickup') {
                if (responseStatus.status === 'success') {
                    setAlert(responseStatus.message, responseStatus.status)
                    const body = {
                        id,
                    }
                    if (buyer_id) {
                        body.user_id = buyer_id
                        body.auction_io = 1
                    }
                    if (type === 'buynow') {
                        body.hasShipping = 0
                        body.isAuction = 0
                    }
                    getInvoiceNew(body)
                }
            }
        }
    }, [responseStatus])

    const downloadPdf = () => {
        setLoading(true)
        html2canvas(inputRef.current, {
            backgroundColor: 'none',
            letterRendering: 1,
            imageTimeout: 100,
            allowTaint: true,
            logging: true,
            useCORS: true,
            dpi: 300,
        }).then((canvas) => {
            const image = canvas.toDataURL('image/png', 1)
            var src = encodeURI(image)
            var pdf = new jsPDF('p', 'mm', 'a4')
            pdf.addImage(src, 'JPEG', 0, 0, 210, 210)
            pdf.save(`${id}-${formatDate()}.pdf`)
            setLoading(false)
        })

        // const content = inputRef.current

        // var doc = new jsPDF()
        // doc.html(content, {
        //     callback: function (doc) {
        //         doc.save(`${id}-${formatDate()}.pdf`)
        //     },
        //     w: '200px',
        // })
        // var doc = new jsPDF()
        // var content = document.querySelector('#downloadInvoice')[0]
        // var specialElementHandlers = {
        //     '#downloadInvoice': (element, renderer) => {
        //         return true
        //     },
        // }
        // doc.formhtml(content, 15, 15, {
        //     pagesplit: true,
        //     background: '#fff',
        //     width: 170,
        //     elementHandlers: specialElementHandlers,
        // })
        // doc.save(`${id}-${formatDate()}.pdf`)
        // setLoading(false)
    }

    //console.log('inputRef.current', inputRef.current)

    const toggleOpen = () => {
        setOpen(!open)
    }
    const sendinvoice = (e) => {
        var data = {
            status: 'email',
            inv_id: id,
        }
        Edit_invoice(data)
    }
    const validationArray = Yup.object({
        amount: Yup.string().required('Required!'),
        sales_tax_amount: Yup.string().required('Required!'),
        shipping_amount: Yup.string().required('Required!'),
        buypremium_amount: Yup.string().required('Required!'),
    })
    const ship_validationArray = Yup.object({
        ship_type: Yup.array().required('Please select atleast one shipping type!'),
    })
    const formik = useFormik({
        initialValues: {
            amount: 0,
            sales_tax_amount: 0,
            shipping_amount: 0,
            buypremium_amount: 0,
            invoiceId: 0,
            transaction_fee: 0,
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            setBtnLoading(true)
            Edit_invoice(values)
        },
    })
    const ship_formik = useFormik({
        initialValues: {
            ship_type: [],
            status: 'shipupdate',
            inv_id: '',
        },
        validationSchema: ship_validationArray,
        onSubmit: async (values) => {
            if (values.ship_type.length > 0) {
                values.inv_id = id
                values.ship_type = values.ship_type.join('<br>')
                if (values.ship_type.includes('Local Pickup') && type === 'buynow') {
                    values.paid_status = 'local_buynow'
                }
                setBtnLoading(true)
                Edit_invoice(values)
            } else {
                setAlert('Please select atleast one shipping type', 'error')
                return false
            }
        },
    })
    const edit_inv = [
        {
            label: 'Amount',
            type: 'number',
            placeholder: 'Enter your Amount',
            class: 'col-sm-6 col-12',
            name: 'amount',
            formik: formik,
        },
        {
            label: `Sales Tax In (%) ${data.tax_excempt ? '(Tax Excemption)' : ''}`,
            type: 'number',
            placeholder: 'Enter your sales tax',
            class: 'col-sm-6 col-12',
            name: 'sales_tax_amount',
            formik: formik,
            disabled: data.tax_excempt ? true : false,
        },
        {
            label: "Buyer's Premium In (%)",
            type: 'number',
            placeholder: "Enter buyer's premium amount",
            class: 'col-sm-6 col-12',
            name: 'buypremium_amount',
            formik: formik,
        },
        {
            label: `Shipping Fee ${data.is_digital ? 'In %' : ''}`,
            type: 'number',
            placeholder: 'Enter your shipping fee',
            class: 'col-sm-6 col-12',
            name: 'shipping_amount',
            formik: formik,
        },
    ]
    const add_ship_type = [
        {
            title: 'Shipping Type',
            type: 'check',
            name: 'ship_type',
            options: [
                {
                    id: 'Local Pickup',
                    description: 'Local Pickup',
                },
                {
                    id: 'Freight / Shipping',
                    description: 'Freight / Shipping',
                },
                {
                    id: 'Packaging, Skidding, Crating',
                    description: 'Packaging, Skidding, Crating',
                },
                {
                    id: 'Consolidate Packaging & Shipping quote',
                    description: 'Consolidate Packaging & Shipping quote',
                },
                {
                    id: 'Removal, Loading',
                    description: 'Removal, Loading',
                },
            ],
            formik: ship_formik,
        },
    ]
    useEffect(() => {
        if (!_.isEmpty(user)) {
            setUserRole(parseInt(user.role ? user.role : 0))
        }
    }, [user])

    useEffect(() => {
        if (global.storeConfigration?.location_with_map?.value == 1) {
            var sen_par_data = `${auctionData.address},${auctionData.city},${auctionData.state},${auctionData.zipcode}`
            map_query.set('q', sen_par_data)
        }
    }, [auctionData, sellerData])

    const view_on_map = () => {
        window.open('https://maps.google.com/?' + map_query.toString())
    }

    const paypalPay = () => {
        setPaypalBtnLoading(true)
        successAfterPayAuctionCalculation({
            invoice_id: [id],
            status: 1,
            user_country: 'US',
            user_id: data[0]?.user_id,
            payment_name: 'Paypal Pay',
        })
    }
    const requestTracking = async (type) => {
        if (type == 1) {
            return window.open(
                `${global.pluginConfiguration?.shipping?.redirect_url}${data[0].tracking_number}`,
            )
        }
        requestTrackingNumber({
            invoice: id,
            site_id: global?.storeDetails?.site_id,
            user_id: user?.id,
        })
    }

    const return_request = (e, id, inv) => {
        var return_id_new = return_id
        return_id_new.push(id)
        setReturnid([...return_id_new])
        make_item_return_request({
            site_id: global?.storeDetails?.site_id,
            user_id: user?.id,
            project_id: id,
            common_inv: inv,
        })
    }

    return (
        <>
            <UserActivity page="invoice_page" />
            <div
                className="offset-xl-2 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 vcInvoice"
                style={{ background: '#fff' }}
            >
                <div className="invcActnBtns d-flex justify-content-between my-3">
                    <div>
                        <Button className="noPrint" onClick={() => history.goBack()}>
                            <span className="material-icons mr-2">west</span>
                            {t('back_to_dashboard')}
                        </Button>
                    </div>

                    {!_.isEmpty(data) ? (
                        <>
                            {data[0].paid == 0 ? (
                                <div>
                                    <Button
                                        className="noPrint"
                                        onClick={paypalPay}
                                        disabled={paypalBtnLoading}
                                    >
                                        {paypalBtnLoading ? 'Loading...' : t('paypal_pay')}
                                    </Button>
                                </div>
                            ) : null}
                            {data[0].paid == 0 && user_role == 1 ? (
                                <>
                                    {global.storeConfigration?.disable_reseller_edit_invoice
                                        ?.value == 1 ? null : (
                                        <Button className="noPrint" onClick={(e) => toggleOpen(e)}>
                                            <span className="material-icons">edit</span>
                                            Edit Invoice
                                        </Button>
                                    )}
                                    {global.storeConfigration?.disable_reseller_send_invoice
                                        ?.value == 1 ? null : (
                                        <Button className="noPrint" onClick={(e) => sendinvoice(e)}>
                                            <span className="material-icons">send</span>
                                            Send Invoice
                                        </Button>
                                    )}
                                </>
                            ) : (
                                ''
                            )}
                            <div>
                                {/* {props.downloadInvoice && (
                                    <Button
                                        className="noPrint mr-2"
                                        onClick={downloadPdf}
                                        disabled={loading}
                                    >
                                        <span className="material-icons mr-2">file_download</span>
                                        {loading ? 'Generating...' : t('download_inv')}
                                    </Button>
                                )} */}
                                <Button className="noPrint" onClick={() => window.print()}>
                                    <span className="material-icons mr-2">print</span>
                                    {t('print_inv')} / {t('download_inv')}
                                </Button>
                            </div>
                        </>
                    ) : (
                        ''
                    )}
                </div>
                {/* <table ref={inputRef} id="downloadInvoice" className="table">
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>Sample</th>
                            <th>Header</th>
                            <th>Value</th>
                            <th>Comes</th>
                        </tr>
                    </thead>
                </table> */}

                <div
                    className="invoiceCard"
                    ref={inputRef}
                    id="downloadInvoice"
                    style={{
                        width: '100%',
                        height: '100%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        background: '#fff',
                    }}
                >
                    {!_.isEmpty(data) && (
                        <>
                            {' '}
                            <div className="card-header d-flex justify-content-between align-items-center p-4">
                                <a className="pt-2 d-inline-block" data-abc="true">
                                    <img
                                        src={`${global?.storeDetails?.logoValue}`}
                                        className="invLogo mb-3 mb-md-0"
                                        alt={global?.storeDetails?.name}
                                        style={{ width: '140px' }}
                                    />

                                    {/* {lotlogo()} */}

                                    {/* <object
                                        type="image/svg+xml"
                                        data={lotlogo}
                                        style={{ width: '140px' }}
                                        alt={global?.storeDetails?.name}
                                    ></object> */}
                                </a>
                                <div className="float-right">
                                    {t('status')}:&nbsp;
                                    {data[0].paid ? (
                                        <span style={{ color: '#67b53e' }}>Paid</span>
                                    ) : (
                                        <span style={{ color: '#f90808' }}>Unpaid</span>
                                    )}
                                    <h5 className="mb-0">
                                        {t('Invoice')} #{id}
                                    </h5>
                                    {t('date')}:{' '}
                                    {formatDateTime(data[0].paid ? data[0].paid_date : new Date())}
                                </div>
                            </div>
                            <div className="card-body" style={{ background: '#fff' }}>
                                {global.storeConfigration?.remove_from_invoice?.value == 1 ? (
                                    <>
                                        <div className="row mb-4">
                                            <div className="col-md-6 text-left">
                                                <h5 className="mb-3">{t('to')}:</h5>
                                                <h3 className="text-dark mb-1">
                                                    {userData.first_name + ' ' + userData.last_name}
                                                </h3>
                                                {global.storeConfigration?.shipping_need?.value ==
                                                    0 ||
                                                global.storeConfigration?.shipping_need ==
                                                    undefined ? (
                                                    <>
                                                        <div>
                                                            {userData.address} {userData?.address1}
                                                        </div>
                                                        <div>
                                                            {userData.city}, {userData.state}{' '}
                                                            {userData.zipcode}
                                                        </div>
                                                        <div>
                                                            {t('phone')}: {userData.phone}
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <div className="col-md-6 text-right">
                                                {data[0].qr_code ? (
                                                    <img
                                                        src={`${process.env.REACT_APP_BASE_URL}uploads/qr/${data[0].qr_code}`}
                                                        className="invLogo float-right img-thumbnail"
                                                        alt="qr_code"
                                                        style={{ width: '140px' }}
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                        <hr />
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className={
                                                global.pluginConfiguration?.remote_seller?.enable &&
                                                data[0].seller_id != global?.storeDetails?.user_id
                                                    ? 'row'
                                                    : 'row mb-4'
                                            }
                                        >
                                            <div
                                                className={
                                                    global.pluginConfiguration?.remote_seller
                                                        ?.enable &&
                                                    data[0].seller_id !=
                                                        global?.storeDetails?.user_id
                                                        ? 'col-3'
                                                        : 'col-sm-4'
                                                }
                                            >
                                                {global.storeConfigration?.remove_from_invoice
                                                    ?.value == 1 ? null : (
                                                    <>
                                                        {props.fromStoreseller ? (
                                                            <>
                                                                <h5 className="mb-3 text-capitalize">
                                                                    {t('from') + ':'}
                                                                </h5>
                                                                <h3 className="text-dark mb-1">
                                                                    {global?.storeDetails?.name}
                                                                </h3>
                                                                <div>
                                                                    {global?.storeSeller?.address1}{' '}
                                                                    {global?.storeSeller?.address2}
                                                                </div>
                                                                <div>
                                                                    {global?.storeSeller?.city}{' '}
                                                                    {global?.storeSeller?.state}{' '}
                                                                    {global?.storeSeller?.zip}
                                                                </div>
                                                                {!global.storeConfigration
                                                                    ?.disable_phone_number
                                                                    ?.value ? (
                                                                    <div>
                                                                        {t('phone') + ':'}{' '}
                                                                        {global?.storeSeller?.phone}
                                                                    </div>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <h5 className="mb-3">
                                                                    {t('From') + ':'}
                                                                </h5>
                                                                {props.fromresellername ? (
                                                                    <h3 className="text-dark mb-1">
                                                                        {sellerData.first_name +
                                                                            ' ' +
                                                                            sellerData.last_name}
                                                                    </h3>
                                                                ) : (
                                                                    <h3 className="text-dark mb-1">
                                                                        {global?.storeDetails?.name}
                                                                    </h3>
                                                                )}
                                                                {sellerData.address1 &&
                                                                global.storeConfigration
                                                                    ?.remove_invoice_from_address
                                                                    ?.value != 1 ? (
                                                                    <>
                                                                        <div>
                                                                            {sellerData.address1}
                                                                            {', '}
                                                                            {sellerData?.address2}
                                                                        </div>
                                                                        <div>
                                                                            {sellerData.city}
                                                                            {', '}
                                                                            {sellerData.state}
                                                                            {', '}
                                                                            {sellerData.zip}
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    ''
                                                                )}
                                                                {!global.storeConfigration
                                                                    ?.disable_phone_number
                                                                    ?.value ? (
                                                                    <div>
                                                                        {t('phone') + ':'}{' '}
                                                                        {global?.storeDetails
                                                                            ?.site_id == 'AS0579' ||
                                                                        global?.storeDetails
                                                                            ?.site_id == 'AS0712'
                                                                            ? sellerData.phone.replace(
                                                                                  '+1',
                                                                                  '',
                                                                              )
                                                                            : sellerData.phone}
                                                                    </div>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            {global.pluginConfiguration?.remote_seller?.enable &&
                                            data[0].seller_id != global?.storeDetails?.user_id ? (
                                                <div
                                                    className={
                                                        global.pluginConfiguration?.remote_seller
                                                            ?.enable &&
                                                        data[0].seller_id !=
                                                            global?.storeDetails?.user_id
                                                            ? 'col-3'
                                                            : 'col-sm-4'
                                                    }
                                                >
                                                    <h5 className="mb-3">Remote Seller Address:</h5>

                                                    <h3 className="text-dark mb-1">
                                                        {sellerData.first_name +
                                                            ' ' +
                                                            sellerData.last_name}
                                                    </h3>

                                                    <div>
                                                        {sellerData.address1}
                                                        {', '}
                                                        {sellerData?.address2}
                                                    </div>
                                                    <div>
                                                        {sellerData.city}
                                                        {', '}
                                                        {sellerData.state}
                                                        {', '}
                                                        {sellerData.zip}
                                                    </div>
                                                    {!global.storeConfigration?.disable_phone_number
                                                        ?.value ? (
                                                        <div>
                                                            {t('phone') + ':'} {sellerData.phone}
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            <div
                                                className={
                                                    global.pluginConfiguration?.remote_seller
                                                        ?.enable &&
                                                    data[0].seller_id !=
                                                        global?.storeDetails?.user_id
                                                        ? 'col-3'
                                                        : 'col-sm-4 mobChngr d-flex justify-content-center'
                                                }
                                            >
                                                {data[0].qr_code ? (
                                                    <img
                                                        src={`${process.env.REACT_APP_BASE_URL}uploads/qr/${data[0].qr_code}`}
                                                        className="invLogo float-right img-thumbnail"
                                                        alt="qr_code"
                                                        style={{ width: '140px' }}
                                                    />
                                                ) : null}
                                            </div>
                                            <div
                                                className={
                                                    global.pluginConfiguration?.remote_seller
                                                        ?.enable &&
                                                    data[0].seller_id !=
                                                        global?.storeDetails?.user_id
                                                        ? 'col-3'
                                                        : 'col-sm-4 text-right text-sm-left mobLeft'
                                                }
                                            >
                                                <h5 className="mb-3">{t('to')}:</h5>
                                                <h3 className="text-dark mb-1">
                                                    {userData.first_name + ' ' + userData.last_name}
                                                </h3>
                                                {global.storeConfigration?.shipping_need?.value ==
                                                    0 ||
                                                global.storeConfigration?.shipping_need ==
                                                    undefined ? (
                                                    <>
                                                        {(userData.address1 && userData.address2) ||
                                                        (userData.address && userData.address1) ? (
                                                            <div>
                                                                {userData.address
                                                                    ? userData.address
                                                                    : userData.address1}
                                                                ,{' '}
                                                                {userData?.address2
                                                                    ? userData.address2
                                                                    : userData.address1}
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                {userData.address
                                                                    ? userData.address
                                                                    : userData.address1
                                                                    ? userData.address1
                                                                    : ''}{' '}
                                                                {userData?.address2
                                                                    ? userData.address2
                                                                    : userData.address1 &&
                                                                      type != 'buynow'
                                                                    ? userData.address1
                                                                    : ''}
                                                            </div>
                                                        )}
                                                        <div>
                                                            {userData.city}, {userData.state}{' '}
                                                            {userData.zipcode}
                                                        </div>
                                                        <div>
                                                            {t('phone')}:{' '}
                                                            {global?.storeDetails?.site_id ==
                                                                'AS0579' ||
                                                            global?.storeDetails?.site_id ==
                                                                'AS0712'
                                                                ? userData.phone.replace('+1', '')
                                                                : userData.phone}
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}

                                {global?.pluginConfiguration?.partial_payment?.enable &&
                                data[0].partial_amount ? (
                                    <>
                                        <h4 className="invoiceTitle">{t('transaction_detai')}</h4>
                                        <div className="table-responsive invoice-data">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>{t('transaction_Id')}</th>
                                                        <th>{t('Payment Method')}</th>
                                                        <th>{t('created_on')}</th>
                                                        <th>{'Amount'}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {payRecordsmultiple.map((data) => (
                                                        <tr>
                                                            <td>#{data?.transid}</td>
                                                            <td>{data?.pay_type}</td>
                                                            <td>{formatDate(data.pay_date)}</td>
                                                            <td>
                                                                {' '}
                                                                {currencyFormat(
                                                                    data.amount,
                                                                    'invoice',
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {data[0].paid == 1 && props.hide_trasaction_detail != 1 ? (
                                            <>
                                                <h4 className="invoiceTitle">
                                                    {t('transaction_detai')}
                                                </h4>
                                                <div className="transDet d-flex justify-content-between flex-wrap">
                                                    <div className="invDiv">
                                                        <p>{t('transaction_Id')}</p>
                                                        <span>#{payRecords?.transid}</span>
                                                    </div>
                                                    <div className="invDiv">
                                                        <p>{t('Payment Method')}</p>
                                                        <span>{payRecords?.pay_type}</span>
                                                    </div>
                                                    <div className="invDiv">
                                                        <p>{t('created_on')}</p>
                                                        <span>{formatDate(data[0].paid_date)}</span>
                                                    </div>
                                                    <div className="invDiv">
                                                        <p>{t('Invoice_status')}</p>
                                                        <span>Successful</span>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                )}
                                {data[0]?.tracking_number &&
                                data[0]?.tracking_service &&
                                data[0]?.shipping_status ? (
                                    <>
                                        <h4 className="invoiceTitle">{t('shipping_details')}</h4>
                                        <div className="transDet d-flex justify-content-between flex-wrap">
                                            <div className="invDiv">
                                                <p>{t('tracking_number')}</p>
                                                <span>#{data[0]?.tracking_number}</span>
                                            </div>
                                            <div className="invDiv">
                                                <p>{t('carrier_name')}</p>
                                                <span>{data[0]?.tracking_service}</span>
                                            </div>
                                            <div className="invDiv">
                                                <p>{t('shipping_status')}</p>
                                                <span>{data[0]?.shipping_status}</span>
                                            </div>
                                        </div>
                                    </>
                                ) : null}

                                {data[0].escrow_payment == 1 && buyer_id ? (
                                    <div style={{ color: 'red' }}>
                                        Buyer Released amount{' '}
                                        {currencyFormat(data[0].additional_amount_3, 'invoice')} To
                                        You.
                                    </div>
                                ) : (
                                    ''
                                )}
                                {global?.storeConfigration?.inv_pro_more_info?.value == 1 ? (
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <PrimaryButton
                                                onClick={(e) => {
                                                    window.open(
                                                        window.location.href.includes(
                                                            global?.storeDetails?.id,
                                                        )
                                                            ? '/' +
                                                                  global?.storeDetails?.id +
                                                                  '/search/product-buyer-auction/' +
                                                                  auctionData.id +
                                                                  '?inv=1'
                                                            : '/search/product-buyer-auction/' +
                                                                  auctionData.id +
                                                                  '?inv=1',
                                                    )
                                                }}
                                                type="button"
                                                label={'View Auction'}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}

                                {props.showSavings ? (
                                    <div className="invoiceTitleContainer">
                                        <h4 className="invoiceTitle mt-5">
                                            {t('product_details')}
                                        </h4>
                                        {savings > 0 ? (
                                            <h4
                                                className="invoiceTitle mt-5"
                                                style={{ color: '#67b53e' }}
                                            >
                                                You&apos;ve scored big by saving an incredible{' '}
                                                {currencyFormat(savings, '', 'invoice')} off retail
                                                pricing
                                            </h4>
                                        ) : null}
                                    </div>
                                ) : (
                                    <h4 className="invoiceTitle mt-5">{t('product_details')}</h4>
                                )}

                                <div className="table-responsive invoice-data">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                {props.showAuctionId ? <th>Auction ID</th> : ''}
                                                {global?.storeConfigration?.hide_lot_id?.value ==
                                                1 ? null : (
                                                    <th className="center">{t('lot_id')}</th>
                                                )}
                                                {global?.storeConfigration?.lot_sequence_order
                                                    ?.value == 1 ? (
                                                    <th>Lot #</th>
                                                ) : (
                                                    ''
                                                )}
                                                {props.show_product_id ? (
                                                    <th className="center">{t('product_id')}</th>
                                                ) : (
                                                    ''
                                                )}
                                                <th width="175">{t('Item')}</th>
                                                {props.lpnNumber ? <th>LPN Number</th> : ''}
                                                {global?.storeConfigration?.buy_now_with_qty
                                                    ?.value == 1 ||
                                                global?.storeConfigration?.inv_pro_more_info
                                                    ?.value == 1 ? (
                                                    <th>{t('Qty')}</th>
                                                ) : (
                                                    ''
                                                )}
                                                {global?.storeConfigration?.show_bin_location
                                                    ?.value == 1 ||
                                                global?.storeConfigration?.show_bin_location
                                                    ?.value == 2 ? (
                                                    <th className="right">{t('bin_location')}</th>
                                                ) : (
                                                    ''
                                                )}
                                                {global?.storeConfigration?.show_lpn_number
                                                    ?.value == 1 ? (
                                                    <th>LPN Number</th>
                                                ) : (
                                                    ''
                                                )}
                                                {/* <th>Inventory</th> */}
                                                {global.storeDetails.theme === 10 ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {!props.noBuyerPremium && (
                                                            <th className="right">
                                                                {t('buyer_premium')}
                                                            </th>
                                                        )}
                                                    </>
                                                )}
                                                {global.storeConfigration?.shipping_need?.value !=
                                                    1 && (
                                                    <>
                                                        {global.storeConfigration
                                                            ?.hide_option_given_to_buyer?.value !=
                                                            1 &&
                                                        parseInt(data[0].auc_custom_field_4) == 1 &&
                                                        user_role == 0 &&
                                                        data[0].shipping_info == '' &&
                                                        parseInt(data[0].shippingcost) == 0 &&
                                                        !open ? (
                                                            <Tooltip
                                                                open={true}
                                                                title={'Select to Pay Invoice'}
                                                                arrow={true}
                                                                placement="top"
                                                                disableHoverListener={true}
                                                                disableFocusListener={true}
                                                            >
                                                                <th className="right">
                                                                    Select Shipping Type
                                                                </th>
                                                            </Tooltip>
                                                        ) : (
                                                            <th className="right">
                                                                {t('shipping_fee')}
                                                            </th>
                                                        )}
                                                    </>
                                                )}
                                                <th className="right">{t('sales_tax')}</th>
                                                {global.storeConfigration?.transfer_item_location
                                                    ?.value == 1 ? (
                                                    <th className="right">{'Transfer Amount'}</th>
                                                ) : (
                                                    ''
                                                )}
                                                {summary.lineItemFee != 0 ? (
                                                    <th className="right">{t('line_item_fee')}</th>
                                                ) : null}
                                                {global.pluginConfiguration?.refund?.enable &&
                                                !global?.pluginConfiguration?.return_assurance
                                                    ?.enable ? (
                                                    <th className="right">Refund</th>
                                                ) : (
                                                    ''
                                                )}

                                                <th className="right">{t('price')}</th>
                                                {props.buyersProtection ? (
                                                    <th>Buyer&apos;s Protection</th>
                                                ) : (
                                                    ''
                                                )}
                                                {global?.pluginConfiguration?.return_assurance
                                                    ?.enable ? (
                                                    <th className="right">Return Item</th>
                                                ) : (
                                                    ''
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((value) => (
                                                <tr>
                                                    {props.showAuctionId ? (
                                                        <td>{value.auc_id}</td>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {global?.storeConfigration?.hide_lot_id
                                                        ?.value == 1 ? null : (
                                                        <td className="center nowrap">
                                                            {/* {value.deed_document || value.id} */}
                                                            {global.storeDetails?.theme == 19
                                                                ? value.lot_id
                                                                : value.sku != '' &&
                                                                  value.sku != '0'
                                                                ? value.sku
                                                                : value.lotof && value.is_upclist
                                                                ? value.lotof +
                                                                  '_' +
                                                                  value.is_upclist
                                                                : value.deed_document
                                                                ? value.deed_document
                                                                : value.id}
                                                        </td>
                                                    )}
                                                    {global?.storeConfigration?.lot_sequence_order
                                                        ?.value == 1 ? (
                                                        <td className="nowrap">
                                                            {value.lot_number
                                                                ? value.lot_number
                                                                : '-'}
                                                        </td>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {props.show_product_id ? (
                                                        <td className="center nowrap">
                                                            {global.storeDetails?.theme == 19
                                                                ? value.id
                                                                : value.store_product_id
                                                                ? value.store_product_id
                                                                : value.id}
                                                        </td>
                                                    ) : (
                                                        ''
                                                    )}

                                                    <td className="left strong">
                                                        <div className="nowrap" title={value.title}>
                                                            {value.title}
                                                        </div>
                                                    </td>
                                                    {props.lpnNumber ? <td>{value.lpn}</td> : ''}
                                                    {/* <td className="left strong">
                                                        {value.inventory_loc != '0' &&
                                                        value.inventory_loc != ''
                                                            ? value.inventory_loc
                                                            : '-'}
                                                    </td> */}
                                                    {global?.storeConfigration?.buy_now_with_qty
                                                        ?.value == 1 ||
                                                    global?.storeConfigration?.inv_pro_more_info
                                                        ?.value == 1 ? (
                                                        <td className="nowrap">{value.qty}</td>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {global?.storeConfigration?.show_bin_location
                                                        ?.value == 1 ||
                                                    global?.storeConfigration?.show_bin_location
                                                        ?.value == 2 ? (
                                                        <td className="right nowrap">
                                                            {value.budget_type
                                                                ? value.budget_type
                                                                : '-'}
                                                        </td>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {global?.storeConfigration?.show_lpn_number
                                                        ?.value == 1 ? (
                                                        <td>{value.lpn}</td>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {global.storeDetails.theme === 10 ? (
                                                        <>
                                                            {props.noBuyerPremium && (
                                                                <td className="right nowrap">
                                                                    {' '}
                                                                    {currencyFormat(
                                                                        value.buypremium_amount +
                                                                            value.selling_fee,
                                                                        '',
                                                                        'invoice',
                                                                    )}{' '}
                                                                    (
                                                                    {value.buypremium +
                                                                        value.featured_fee}
                                                                    %)
                                                                </td>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {!props.noBuyerPremium && (
                                                                <td className="right nowrap">
                                                                    {' '}
                                                                    {currencyFormat(
                                                                        value.buypremium_amount +
                                                                            value.selling_fee,
                                                                        '',
                                                                        'invoice',
                                                                    )}{' '}
                                                                    (
                                                                    {value.buypremium +
                                                                        value.featured_fee}
                                                                    %)
                                                                </td>
                                                            )}
                                                        </>
                                                    )}
                                                    {global.storeConfigration?.shipping_need
                                                        ?.value != 1 && (
                                                        <>
                                                            {global.storeConfigration
                                                                ?.hide_option_given_to_buyer
                                                                ?.value != 1 &&
                                                            parseInt(value.auc_custom_field_4) ==
                                                                1 &&
                                                            user_role == 0 &&
                                                            value.shipping_info == '' &&
                                                            parseInt(value.shippingcost) == 0 ? (
                                                                <td className="right">
                                                                    <Button
                                                                        className="noPrint"
                                                                        onClick={(e) =>
                                                                            toggleOpen(e)
                                                                        }
                                                                    >
                                                                        <span className="material-icons">
                                                                            edit
                                                                        </span>
                                                                        Add Type
                                                                    </Button>

                                                                    {/* <PrimaryButton
                                                           
                                                            type="button"
                                                            disabled={btnLoading}
                                                            label={
                                                                btnLoading
                                                                    ? 'Loading...'
                                                                    : 'Add Type'
                                                            }
                                                        /> */}
                                                                </td>
                                                            ) : (
                                                                <td className="right nowrap">
                                                                    {currencyFormat(
                                                                        value.shipping_info
                                                                            ? value.shippingcost
                                                                            : value.shipping_amount,
                                                                        '',
                                                                        'invoice',
                                                                    )}{' '}
                                                                    {value.additional_amount_1
                                                                        ? `(${value.additional_amount_1}%)`
                                                                        : ''}
                                                                </td>
                                                            )}
                                                        </>
                                                    )}
                                                    <td className="right nowrap">
                                                        {currencyFormat(
                                                            value.sales_tax_amount,
                                                            '',
                                                            'invoice',
                                                        )}{' '}
                                                        {global.pluginConfiguration?.avalara?.enable
                                                            ? ''
                                                            : `
                                                        (${
                                                            type === 'buynow' ||
                                                            parseInt(value.paid) == 0
                                                                ? value.sales_tax
                                                                : value.salestax_amt
                                                        }
                                                        %)`}
                                                    </td>
                                                    {global.storeConfigration
                                                        ?.transfer_item_location?.value == 1 ? (
                                                        <td className="right nowrap">
                                                            {currencyFormat(
                                                                value.transfer_loc_fee,
                                                                '',
                                                                'invoice',
                                                            )}
                                                        </td>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {summary.lineItemFee != 0 ? (
                                                        <td className="right nowrap">
                                                            {currencyFormat(
                                                                value.line_item_fee,
                                                                '',
                                                                'invoice',
                                                            )}
                                                        </td>
                                                    ) : null}
                                                    {global.pluginConfiguration?.refund?.enable &&
                                                    !global?.pluginConfiguration?.return_assurance
                                                        ?.enable ? (
                                                        <td className="right nowrap">
                                                            <p
                                                                style={
                                                                    parseInt(value.item_returned)
                                                                        ? { color: 'red' }
                                                                        : {}
                                                                }
                                                            >
                                                                {parseInt(value.item_returned)
                                                                    ? `Refunded on (${dateFormatFunction(
                                                                          value.refund_request_date,
                                                                      )})`
                                                                    : '-'}
                                                            </p>
                                                        </td>
                                                    ) : (
                                                        ''
                                                    )}
                                                    <td className="right nowrap">
                                                        {currencyFormat(
                                                            value.amount,
                                                            '',
                                                            'invoice',
                                                        )}
                                                    </td>
                                                    {props.buyersProtection ? (
                                                        <td>
                                                            {value.buyerassurancevalue > 0
                                                                ? 'YES'
                                                                : 'NO'}
                                                        </td>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {global?.pluginConfiguration?.return_assurance
                                                        ?.enable ? (
                                                        <>
                                                            {!return_id.includes(
                                                                value.project_id,
                                                            ) &&
                                                            value.buynow_check == 0 &&
                                                            parseFloat(value.buyerassurancevalue) >
                                                                0 ? (
                                                                <td className="right nowrap">
                                                                    <PrimaryButton
                                                                        onClick={(e) =>
                                                                            return_request(
                                                                                e,
                                                                                value.project_id,
                                                                                value.common_invoice,
                                                                            )
                                                                        }
                                                                        label={
                                                                            returbbtn
                                                                                ? 'Loading...'
                                                                                : 'Return Item'
                                                                        }
                                                                        disabled={btnLoading}
                                                                    ></PrimaryButton>
                                                                </td>
                                                            ) : (value.buynow_check == 1 ||
                                                                  return_id.includes(
                                                                      value.project_id,
                                                                  )) &&
                                                              parseFloat(
                                                                  value.buyerassurancevalue,
                                                              ) > 0 &&
                                                              !parseInt(value.item_returned) ? (
                                                                <td>Return Requested</td>
                                                            ) : parseInt(value.item_returned) ? (
                                                                <td>
                                                                    {`Refunded on (${dateFormatFunction(
                                                                        value.refund_request_date,
                                                                    )})`}
                                                                </td>
                                                            ) : (
                                                                <td>-</td>
                                                            )}
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-12">
                                        <div className="table-responsive">
                                            <table className="table table-clear vcInvTot">
                                                <tbody>
                                                    <tr>
                                                        <td className="left">
                                                            <span className="text-dark">
                                                                {t('amount')}
                                                            </span>{' '}
                                                        </td>
                                                        <td className="right nowrap">
                                                            <span className="text-dark">
                                                                {currencyFormat(
                                                                    summary.amount,
                                                                    '',
                                                                    'invoice',
                                                                )}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    {global.storeDetails.theme === 10 ? (
                                                        <>
                                                            {props.noBuyerPremium && (
                                                                <tr>
                                                                    <td className="left">
                                                                        <span className="text-dark">
                                                                            {t('buyer_premium')} (
                                                                            {
                                                                                summary.buyerPremiumPercentage
                                                                            }
                                                                            %)
                                                                        </span>{' '}
                                                                    </td>
                                                                    <td className="right nowrap">
                                                                        <span className="text-dark">
                                                                            {currencyFormat(
                                                                                summary.buyerPremium,
                                                                                '',
                                                                                'invoice',
                                                                            )}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {!props.noBuyerPremium && (
                                                                <tr>
                                                                    <td className="left">
                                                                        <span className="text-dark">
                                                                            {t('buyer_premium')} (
                                                                            {
                                                                                summary.buyerPremiumPercentage
                                                                            }
                                                                            %)
                                                                        </span>{' '}
                                                                    </td>
                                                                    <td className="right nowrap">
                                                                        <span className="text-dark">
                                                                            {currencyFormat(
                                                                                summary.buyerPremium,
                                                                                '',
                                                                                'invoice',
                                                                            )}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </>
                                                    )}
                                                    {global.storeConfigration?.shipping_need
                                                        ?.value != 1 && (
                                                        <tr>
                                                            {global.storeConfigration
                                                                ?.hide_option_given_to_buyer
                                                                ?.value != 1 &&
                                                            parseInt(data[0].auc_custom_field_4) ==
                                                                1 &&
                                                            user_role == 0 &&
                                                            data[0].shipping_info == '' &&
                                                            parseInt(data[0].shippingcost) == 0 &&
                                                            !open ? (
                                                                <td className="left">
                                                                    <span className="text-dark">
                                                                        Select Shipping Type
                                                                    </span>{' '}
                                                                </td>
                                                            ) : (
                                                                <td className="left ">
                                                                    <span className="text-dark">
                                                                        {t('shipping_fee')}
                                                                        {summary.shippingPercentage
                                                                            ? `(${summary.shippingPercentage}%)`
                                                                            : ''}
                                                                    </span>{' '}
                                                                </td>
                                                            )}

                                                            {global.storeConfigration
                                                                ?.hide_option_given_to_buyer
                                                                ?.value != 1 &&
                                                            parseInt(data[0].auc_custom_field_4) ==
                                                                1 &&
                                                            user_role == 0 &&
                                                            data[0].shipping_info == '' &&
                                                            parseInt(data[0].shippingcost) == 0 &&
                                                            !open ? (
                                                                <td className="right nowrap">
                                                                    {/* <PrimaryButton
                                                                    onClick={(e) => toggleOpen(e)}
                                                                    type="button"
                                                                    disabled={btnLoading}
                                                                    label={
                                                                        btnLoading
                                                                            ? 'Loading...'
                                                                            : 'Add Type'
                                                                    }
                                                                /> */}
                                                                    <Tooltip
                                                                        open={true}
                                                                        title={
                                                                            'Select to Pay Invoice'
                                                                        }
                                                                        arrow={true}
                                                                        placement="right"
                                                                        disableHoverListener={true}
                                                                        disableFocusListener={true}
                                                                    >
                                                                        <Button
                                                                            className="noPrint"
                                                                            onClick={(e) =>
                                                                                toggleOpen(e)
                                                                            }
                                                                        >
                                                                            <span className="material-icons">
                                                                                edit
                                                                            </span>
                                                                            Add Type
                                                                        </Button>
                                                                    </Tooltip>
                                                                </td>
                                                            ) : (
                                                                <td className="right nowrap">
                                                                    <span className="text-dark">
                                                                        {currencyFormat(
                                                                            summary.shippingCost,
                                                                            '',
                                                                            'invoice',
                                                                        )}
                                                                    </span>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <td className="left">
                                                            {data[0].sst ? (
                                                                <span className="text-dark">
                                                                    Sales Tax + Local Tax (
                                                                    {data[0].sst + ' % '}+{' '}
                                                                    {data[0].lst + ' %'})
                                                                </span>
                                                            ) : global.pluginConfiguration?.avalara
                                                                  ?.enable ? (
                                                                <span className="text-dark">
                                                                    {t('sales_tax')}
                                                                </span>
                                                            ) : (
                                                                <span className="text-dark">
                                                                    {t('sales_tax')} (
                                                                    {type === 'buynow' ||
                                                                        summary.salesTaxPercentage}
                                                                    %)
                                                                </span>
                                                            )}{' '}
                                                        </td>
                                                        <td className="right nowrap">
                                                            <span className="text-dark">
                                                                {currencyFormat(
                                                                    summary.salesTax,
                                                                    '',
                                                                    'invoice',
                                                                )}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    {global.storeConfigration
                                                        ?.transfer_item_location?.value == 1 ? (
                                                        <tr>
                                                            <td className="left">
                                                                Transfer Amount
                                                            </td>
                                                            <td className="right nowrap">
                                                                <span className="text-dark">
                                                                    {currencyFormat(
                                                                        summary.transfer_fee,
                                                                        '',
                                                                        'invoice',
                                                                    )}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {summary.transactionFee != 0 ? (
                                                        <tr>
                                                            <td className="left">
                                                                <span className="text-dark">
                                                                    {t('transaction_fee')} (
                                                                    {data[0].label_data
                                                                        ? data[0].label_data
                                                                        : '3.1% + 30 Cents'}
                                                                    )
                                                                </span>{' '}
                                                            </td>
                                                            <td className="right nowrap">
                                                                <span className="text-dark">
                                                                    {/* //untill comple configuartion */}
                                                                    {currencyFormat(
                                                                        summary.transactionFee,
                                                                        '',
                                                                        'invoice',
                                                                    )}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                    {summary.lineItemFee != 0 ? (
                                                        <tr>
                                                            <td className="left">
                                                                <span className="text-dark">
                                                                    {t('line_item_fee')}
                                                                </span>{' '}
                                                            </td>
                                                            <td className="right nowrap">
                                                                <span className="text-dark">
                                                                    {currencyFormat(
                                                                        summary.lineItemFee,
                                                                        '',
                                                                        'invoice',
                                                                    )}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                    {summary?.buyerassurancevalue > 0 ? (
                                                        <tr>
                                                            <td className="left">
                                                                <span className="text-dark">
                                                                    {t('Buyer Protection Value')}
                                                                </span>
                                                            </td>
                                                            <td className="right nowrap">
                                                                <span className="text-dark">
                                                                    {' '}
                                                                    {currencyFormat(
                                                                        summary.buyerassurancevalue,
                                                                        '',
                                                                        'invoice',
                                                                    )}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ) : null}

                                                    <tr style={{ background: '#f3f3f3' }}>
                                                        <td className="left">
                                                            <strong className="text-dark">
                                                                {parseFloat(discount_amount_tot) >
                                                                    0 ||
                                                                parseFloat(
                                                                    summary.dis_count_amount,
                                                                ) > 0
                                                                    ? t('sub_total')
                                                                    : t('grand_total')}
                                                            </strong>
                                                        </td>
                                                        <td className="right nowrap">
                                                            <strong className="text-dark">
                                                                {' '}
                                                                {currencyFormat(
                                                                    summary.total_invoice,
                                                                    '',
                                                                    'invoice',
                                                                )}
                                                            </strong>
                                                        </td>
                                                    </tr>
                                                    {summary?.additional_payments > 0 ? (
                                                        <tr
                                                            style={{
                                                                background: '#f3f3f3',
                                                            }}
                                                        >
                                                            <td className="left">
                                                                <strong className="text-dark">
                                                                    {t('Additional Payments')}
                                                                </strong>
                                                            </td>
                                                            <td className="right nowrap">
                                                                <strong className="text-dark">
                                                                    {' '}
                                                                    {currencyFormat(
                                                                        summary.additional_payments,
                                                                        '',
                                                                        'invoice',
                                                                    )}
                                                                </strong>
                                                            </td>
                                                        </tr>
                                                    ) : null}

                                                    {summary.additional_amount_2 > 0 ? (
                                                        <>
                                                            <tr style={{ background: '#f3f3f3' }}>
                                                                <td className="left">
                                                                    <strong className="text-dark">
                                                                        {t('deposit_amount')}
                                                                    </strong>
                                                                </td>
                                                                <td className="right nowrap">
                                                                    <strong className="text-dark">
                                                                        {' '}
                                                                        {currencyFormat(
                                                                            summary.additional_amount_2,
                                                                            '',
                                                                            'invoice',
                                                                        )}
                                                                    </strong>
                                                                </td>
                                                            </tr>
                                                            {/* <tr style={{ background: '#f3f3f3' }}>
                                                                <td className="left">
                                                                    <strong className="text-dark">
                                                                        {t('amount_due')}
                                                                    </strong>
                                                                </td>
                                                                <td className="right">
                                                                    <strong className="text-dark">
                                                                        {' '}
                                                                        {currencyFormat(
                                                                            summary.total_invoice -
                                                                                summary.additional_amount_2,
                                                                            '',
                                                                            'invoice',
                                                                        )}
                                                                    </strong>
                                                                </td>
                                                            </tr> */}
                                                        </>
                                                    ) : null}
                                                    {parseFloat(discount_amount_tot) > 0 ? (
                                                        <>
                                                            <tr style={{ background: '#f3f3f3' }}>
                                                                <td className="left">
                                                                    <strong className="text-dark">
                                                                        {t('discount_amount')}
                                                                    </strong>
                                                                </td>
                                                                <td className="right nowrap">
                                                                    <strong className="text-dark">
                                                                        {' '}
                                                                        {' - ' +
                                                                            currencyFormat(
                                                                                discount_amount_tot,
                                                                                '',
                                                                                'invoice',
                                                                            )}
                                                                    </strong>
                                                                </td>
                                                            </tr>
                                                            <tr style={{ background: '#f3f3f3' }}>
                                                                <td className="left">
                                                                    <strong className="text-dark">
                                                                        {t('grand_total')}
                                                                    </strong>
                                                                </td>
                                                                <td className="right nowrap">
                                                                    <strong className="text-dark">
                                                                        {' '}
                                                                        {currencyFormat(
                                                                            parseFloat(
                                                                                parseFloat(
                                                                                    summary.total_invoice,
                                                                                ) -
                                                                                    parseFloat(
                                                                                        discount_amount_tot,
                                                                                    ),
                                                                            ).toFixed(2),
                                                                            '',
                                                                            'invoice',
                                                                        )}
                                                                    </strong>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {parseFloat(summary.dis_count_amount) > 0 ? (
                                                        <>
                                                            <tr style={{ background: '#f3f3f3' }}>
                                                                <td className="left">
                                                                    <strong className="text-dark">
                                                                        {t('discount_amount')}
                                                                    </strong>
                                                                </td>
                                                                <td className="right nowrap">
                                                                    <strong className="text-dark">
                                                                        {' '}
                                                                        {' - ' +
                                                                            currencyFormat(
                                                                                summary.dis_count_amount,
                                                                                '',
                                                                                'invoice',
                                                                            )}
                                                                    </strong>
                                                                </td>
                                                            </tr>
                                                            <tr style={{ background: '#f3f3f3' }}>
                                                                <td className="left">
                                                                    <strong className="text-dark">
                                                                        {t('grand_total')}
                                                                    </strong>
                                                                </td>
                                                                <td className="right nowrap">
                                                                    <strong className="text-dark">
                                                                        {' '}
                                                                        {currencyFormat(
                                                                            parseFloat(
                                                                                parseFloat(
                                                                                    summary.total_invoice,
                                                                                ) -
                                                                                    parseFloat(
                                                                                        summary.dis_count_amount,
                                                                                    ),
                                                                            ).toFixed(2),
                                                                            '',
                                                                            'invoice',
                                                                        )}
                                                                    </strong>
                                                                </td>
                                                            </tr>
                                                            {summary?.additional_payments > 0 ? (
                                                                <tr
                                                                    style={{
                                                                        background: '#f3f3f3',
                                                                    }}
                                                                >
                                                                    <td className="left">
                                                                        <strong className="text-dark">
                                                                            {t(
                                                                                'Additional Payments',
                                                                            )}
                                                                        </strong>
                                                                    </td>
                                                                    <td className="right nowrap">
                                                                        <strong className="text-dark">
                                                                            {' '}
                                                                            {currencyFormat(
                                                                                summary.additional_payments,
                                                                                '',
                                                                                'invoice',
                                                                            )}
                                                                        </strong>
                                                                    </td>
                                                                </tr>
                                                            ) : null}
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {data[0].shipping_info &&
                                                    type === 'buynow' &&
                                                    data[0].shipping_info != 'Local Pickup' &&
                                                    parseInt(data[0].paid) == 0 ? (
                                                        <>
                                                            <tr style={{ background: '#f3f3f3' }}>
                                                                <td className="left">
                                                                    <strong className="text-dark">
                                                                        Paid Amount
                                                                    </strong>
                                                                </td>
                                                                <td className="right nowrap">
                                                                    <strong className="text-dark">
                                                                        {' '}
                                                                        {currencyFormat(
                                                                            parseFloat(
                                                                                summary.total_invoice,
                                                                            ) -
                                                                                parseFloat(
                                                                                    summary.shippingCost,
                                                                                ),
                                                                            '',
                                                                            'invoice',
                                                                        )}
                                                                    </strong>
                                                                </td>
                                                            </tr>
                                                            <tr style={{ background: '#f3f3f3' }}>
                                                                <td className="left">
                                                                    <strong className="text-dark">
                                                                        Due Amount + Transaction
                                                                        Fee(3.1% + 30 Cents)
                                                                    </strong>
                                                                </td>
                                                                <td className="right nowrap">
                                                                    <strong className="text-dark">
                                                                        {' '}
                                                                        {currencyFormat(
                                                                            parseFloat(
                                                                                summary.shippingCost,
                                                                            ) +
                                                                                parseFloat(
                                                                                    summary.new_transaction_fee,
                                                                                ),
                                                                            '',
                                                                            'invoice',
                                                                        )}
                                                                    </strong>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    {data[0].shipping_info &&
                                    type === 'buynow' &&
                                    data[0].shipping_info != 'Local Pickup' &&
                                    parseInt(data[0].paid) == 0 ? (
                                        <div className="col-12">
                                            <ShippingpaymentComponent
                                                amount={summary.shippingCost}
                                                invoice_id={id}
                                                invoice_req={{ hasShipping: 0, isAuction: 0, id }}
                                                call_after_payment={(body) => getInvoiceNew(body)}
                                            ></ShippingpaymentComponent>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <ScheduleLocalPickup
                                    auctionData={auctionData}
                                    sellerData={sellerData}
                                    transferOtp={data[0].transfer_otp}
                                    invoice_id={id}
                                    local_pickup_date={data[0].sche_localpick_local}
                                    view_on_map={view_on_map}
                                />
                                {global?.storeConfigration?.new_schedule_pickup?.value == 1 &&
                                schedulePickup?.status !== 'completed' ? (
                                    <>
                                        <Pickup
                                            invoice={data[0]}
                                            pickupData={schedulePickup}
                                            auctionData={auctionData}
                                            sellerData={sellerData}
                                            trigger={trigger}
                                            setTrigger={setTrigger}
                                            lotCount={data.length}
                                        />
                                    </>
                                ) : null}
                                {global?.storeConfigration?.new_schedule_pickup_1?.value == 1 &&
                                global?.storeConfigration?.new_schedule_pickup?.value != 1 &&
                                schedulePickup?.status !== 'completed' ? (
                                    <>
                                        <NewSchedulePickup
                                            invoice={data[0]}
                                            pickupData={schedulePickup}
                                            auctionData={auctionData}
                                        />
                                    </>
                                ) : null}

                                <div className="row mb-4">
                                    {(parseInt(user?.id ? user.id : 0) ===
                                        parseInt(data[0].buyer_id) &&
                                        global.storeDetails.theme == 7) ||
                                    (global.storeDetails.theme != 7 &&
                                        parseInt(summary.local_pick) == 1) ? (
                                        <>
                                            {global.storeConfigration?.remove_invoice_pickup_address
                                                ?.value == 1 ? null : (
                                                <div className="col-sm-6">
                                                    {global.storeConfigration
                                                        ?.include_auction_local_pickup?.value !=
                                                        1 &&
                                                    global.storeConfigration?.location_with_map
                                                        ?.value != 1 ? (
                                                        <>
                                                            <h5 className="mb-2">
                                                                <b>Local Pickup Address:</b>
                                                            </h5>

                                                            <div>
                                                                {auctionData.address
                                                                    ? auctionData.address
                                                                    : sellerData.address1 +
                                                                      ' ' +
                                                                      sellerData?.address2}
                                                            </div>
                                                            {/* <div>{auctionData.country}</div> */}
                                                            <div>
                                                                {auctionData.city
                                                                    ? auctionData.city
                                                                    : sellerData.city}{' '}
                                                                {auctionData.state
                                                                    ? auctionData.state
                                                                    : sellerData.state}{' '}
                                                                {auctionData.zipcode
                                                                    ? auctionData.zipcode
                                                                    : sellerData.zipcode}
                                                            </div>
                                                        </>
                                                    ) : null}

                                                    {global.storeConfigration?.location_with_map
                                                        ?.value == 1 ? (
                                                        <>
                                                            <h5 className="mb-2">
                                                                <b>Local Pickup Address:</b>
                                                            </h5>
                                                            <a
                                                                style={{
                                                                    color: '#007bffcf',
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => view_on_map()}
                                                            >
                                                                <div>
                                                                    {auctionData.address
                                                                        ? auctionData.address
                                                                        : sellerData.address1 +
                                                                          ' ' +
                                                                          sellerData?.address2}
                                                                </div>
                                                                {/* <div>{auctionData.country}</div> */}
                                                                <div>
                                                                    {auctionData.city
                                                                        ? auctionData.city
                                                                        : sellerData.city}{' '}
                                                                    {auctionData.state
                                                                        ? auctionData.state
                                                                        : sellerData.state}{' '}
                                                                    {auctionData.zipcode
                                                                        ? auctionData.zipcode
                                                                        : sellerData.zipcode}
                                                                </div>
                                                            </a>
                                                        </>
                                                    ) : null}

                                                    {global.storeConfigration
                                                        ?.disabled_phone_from_posting?.value == 0 ||
                                                    global.storeConfigration
                                                        ?.disabled_phone_from_posting ==
                                                        undefined ? (
                                                        <div>
                                                            Phone:{' '}
                                                            {auctionData.phone
                                                                ? auctionData.phone
                                                                : sellerData.phone}
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {global.storeConfigration?.payment_interval
                                                        ?.value == 1 ? (
                                                        <div>
                                                            Pickup Code: {data[0].transfer_otp}
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                    <p className="mt-3 mb-0">
                                                        {ReactHtmlParser(
                                                            global.storeConfigration
                                                                ?.local_pickup_description?.value,
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                        </>
                                    ) : null}

                                    {(parseInt(user?.id ? user.id : 0) ===
                                        parseInt(data[0].buyer_id) &&
                                        global.storeDetails.theme == 7) ||
                                    (global.storeDetails.theme != 7 &&
                                        parseInt(summary.local_pick) == 1) ? (
                                        <>
                                            {global.storeConfigration?.mon?.value &&
                                            global.storeConfigration?.include_auction_local_pickup
                                                ?.value != 1 ? (
                                                <div className="col-sm-6">
                                                    <h5 className="mb-4">
                                                        <strong>Hours of Operation</strong>
                                                    </h5>
                                                    {global.storeConfigration?.mon?.value ? (
                                                        <p>
                                                            Monday:{' '}
                                                            {global.storeConfigration?.mon?.value}
                                                        </p>
                                                    ) : null}
                                                    {global.storeConfigration?.tue?.value ? (
                                                        <p>
                                                            Tuesday:{' '}
                                                            {global.storeConfigration?.tue?.value}
                                                        </p>
                                                    ) : null}
                                                    {global.storeConfigration?.wed?.value ? (
                                                        <p>
                                                            Wednesday:{' '}
                                                            {global.storeConfigration?.wed?.value}
                                                        </p>
                                                    ) : null}
                                                    {global.storeConfigration?.thu?.value ? (
                                                        <p>
                                                            Thursday:{' '}
                                                            {global.storeConfigration?.thu?.value}
                                                        </p>
                                                    ) : null}
                                                    {global.storeConfigration?.fri?.value ? (
                                                        <p>
                                                            Friday:{' '}
                                                            {global.storeConfigration?.fri?.value}
                                                        </p>
                                                    ) : null}
                                                    {global.storeConfigration?.sat?.value ? (
                                                        <p>
                                                            Saturday:{' '}
                                                            {global.storeConfigration?.sat?.value}
                                                        </p>
                                                    ) : null}
                                                    {global.storeConfigration?.sun?.value ? (
                                                        <p>
                                                            Sunday:{' '}
                                                            {global.storeConfigration?.sun?.value}
                                                        </p>
                                                    ) : null}
                                                </div>
                                            ) : null}
                                            {global.storeConfigration?.include_auction_local_pickup
                                                ?.value == 1 ? (
                                                <div className="col-sm-6">
                                                    {ReactHtmlParser(
                                                        auctionData?.custom_field_6
                                                            ? auctionData?.custom_field_6
                                                            : data[0]?.extbidding,
                                                    )}
                                                </div>
                                            ) : null}
                                        </>
                                    ) : null}
                                </div>

                                {summary.shipping == 1 ? (
                                    <div className="row mb-4">
                                        <div className="col-sm-6">
                                            <h3 className="mb-1">
                                                <strong>
                                                    <u>Shipping Details</u>
                                                </strong>
                                            </h3>
                                            <p>
                                                {
                                                    global.storeConfigration
                                                        ?.outside_vancouver_message?.value
                                                }
                                            </p>
                                        </div>
                                    </div>
                                ) : null}
                                {data[0].hasshipping == 1 &&
                                global.pluginConfiguration?.shipping?.enable ? (
                                    <div className="row mb-4">
                                        <div className="col-sm-6">
                                            {buyer_id ? (
                                                <Button
                                                    className="noPrint"
                                                    onClick={() =>
                                                        requestTracking(
                                                            data[0]?.tracking_number ? 1 : 0,
                                                        )
                                                    }
                                                    disabled={
                                                        data[0]?.request_tracking == 1 &&
                                                        !data[0]?.tracking_number
                                                    }
                                                >
                                                    {data[0]?.tracking_number
                                                        ? `${t('tracking_number')}: ${
                                                              data[0]?.tracking_number
                                                          }`
                                                        : data[0]?.request_tracking == 0
                                                        ? t('request_tracking')
                                                        : parseInt(data[0]?.user_id) ==
                                                          parseInt(user?.id)
                                                        ? t('tracking_update')
                                                        : t('tracking_update_seller')}
                                                </Button>
                                            ) : (
                                                <Button
                                                    className="noPrint"
                                                    onClick={() => requestTracking(1)}
                                                    disabled={
                                                        data[0]?.request_tracking == 1 &&
                                                        !data[0]?.tracking_number
                                                    }
                                                >
                                                    {data[0]?.tracking_number
                                                        ? `${t('tracking_number')}: ${
                                                              data[0]?.tracking_number
                                                          }`
                                                        : parseInt(data[0]?.user_id) ==
                                                          parseInt(user?.id)
                                                        ? t('tracking_update')
                                                        : t('tracking_update_seller')}
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                ) : null}
                                {props.noRefundMessage && (
                                    <div className="noRefndPoly text-center">
                                        <hr />
                                        <p className="m-0">No Refund Policy or Returns</p>
                                    </div>
                                )}
                                <div className="mt-3 mb-0 text-center">
                                    {ReactHtmlParser(
                                        global.storeConfigration?.invoice_return_policy?.value,
                                    )}
                                </div>
                                {global.storeDetails.theme == 19 && (
                                    <InvoiceTerms auctionData={auctionData} />
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <Popup
                open={open}
                handleClose={toggleOpen}
                size="md"
                className="save-search-modal"
                modaltitle={
                    parseInt(data[0]?.localpickup) == 0 &&
                    parseInt(data[0]?.hasshipping) == 0 &&
                    user_role == 0
                        ? 'shipping option'
                        : 'Edit Invoice'
                }
            >
                <>
                    {parseInt(data[0]?.localpickup) == 0 &&
                    parseInt(data[0]?.hasshipping) == 0 &&
                    user_role == 0 ? (
                        <div className="row">
                            <div className="col-12 col-sm-4"></div>
                            <div>
                                Please Select Shipping Type
                                {mapData(add_ship_type)}
                            </div>
                        </div>
                    ) : (
                        <div className="row">{mapData(edit_inv)}</div>
                    )}

                    <div className="actionWrapper">
                        {/* <Button onClick={() => setOpen(false)} disabled={btnLoading}>
                            {'Cancel'}
                        </Button> */}
                        <div className="row">
                            <div className="col-sm-3 col-12"></div>
                            <div className="col-md-6">
                                {parseInt(data[0]?.localpickup) == 0 &&
                                parseInt(data[0]?.hasshipping) == 0 &&
                                user_role == 0 ? (
                                    <PrimaryButton
                                        onClick={(e) => ship_formik.handleSubmit(e)}
                                        type="button"
                                        disabled={btnLoading}
                                        label={btnLoading ? 'Loading...' : 'Update'}
                                    />
                                ) : (
                                    <PrimaryButton
                                        onClick={(e) => formik.handleSubmit(e)}
                                        type="button"
                                        disabled={btnLoading}
                                        label={btnLoading ? 'Loading...' : 'Update'}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </>
            </Popup>

            <Popup
                open={openinfo}
                handleClose={() => setOpeninfo(false)}
                size="md"
                className="save-search-modal"
                modaltitle={'Info'}
            >
                <>
                    <div className="row">
                        <div className="col-12">
                            Hand over the item to the warehouse so we can process the refund.
                            Thanks!
                        </div>
                    </div>
                </>
            </Popup>
        </>
    )
}

export default Invoice
