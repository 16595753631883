import React, { useState, useContext, useEffect } from 'react'
import PaginationComponent from '../../../Component/Pagination'
import { Link, useHistory } from 'react-router-dom'
import Bidhistory from '../../../Component/Bidhistory'
import { getFilePath } from '../../../utils/commonFunctions'
import './product.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Timer from '../../../Product/common/timer'
import Bidding from '../../../Product/components/molecules/Bidding/BiddingItem'
import AuthContext from '../../../Product/context/auth/authContext'
import AddToCart from '../../../utils/CommonFunctionality/Cart/AddToCart'
import TableView from '../../../Component/ProductCard/TableView'
import { socket } from '../../../Product/common/socket'
import moment from 'moment'
import SearchProductList from '../../../Component/ProductCard/product'
import { Button } from '@material-ui/core'
import { getAdsImage, shuffleArray } from '../../../Product/common/components'
import { useMediaQuery } from 'react-responsive'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'

const SubLotsComponent = (props) => {
    const authContext = useContext(AuthContext)
    const [selectedHistory, setSelectedHistory] = useState({})
    const { user, isAuthenticated } = authContext
    const [auctionTimer, setAuctionTimer] = useState('')
    const history = useHistory()
    const params = new URLSearchParams(window.location.search.substring(1))
    const [auctionView, setAuctionView] = useState(params.get('view') ? params.get('view') : 'List')
    const openHistory = (property) => {
        setSelectedHistory(property)
    }
    const totalAdsCount = Math.ceil(props.lotData?.length / 5)
    const handleAuctionView = (type) => {
        let currentUrlParams = new URLSearchParams(window.location.search)
        currentUrlParams.set('view', type)
        history.push(history.location.pathname + '?' + currentUrlParams.toString())
    }

    // useEffect(() => {
    //     if (socket) {
    //         socket.on('sliservertime', (data) => {
    //             if (moment(data.dTime).isValid()) {
    //                 setAuctionTimer(data.dTime)
    //             }
    //         })
    //     }
    // }, [socket])

    const isMobile = useMediaQuery({
        query: '(max-width: 500px)',
    })

    useEffect(() => {
        setAuctionView(params.get('view') ? params.get('view') : 'List')
    }, [window.location.search])

    const imageChangeInterval = global?.pluginConfiguration?.advertisement?.refresh_key * 60000 // Convert minutes to milliseconds

    const [aucAds, setAucAds] = useState({
        deskAucRight: '',
        deskAucLeft: '',
        mobAucTop: '',
        mobAucBottom: '',
    })

    const [activeAucAdsIndex, setActiveAucAdsIndex] = useState({
        deskAucRight: 0,
        deskAucLeft: 0,
        mobAucTop: 0,
        mobAucBottom: 0,
    })
    useEffect(() => {
        setAucAds((prevAucAds) => ({
            deskAucRight: shuffleArray(getAdsImage(1, 2, 4)),
            deskAucLeft: shuffleArray(getAdsImage(1, 2, 3)),
            mobAucTop: shuffleArray(getAdsImage(2, 2, 1)),
            mobAucBottom: shuffleArray(getAdsImage(2, 2, 5)),
        }))
    }, [])

    function updateRandomIndex(array) {
        const randomIndex = Math.floor(Math.random() * array.length)
        return randomIndex
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveAucAdsIndex((prevActiveIndex) => ({
                deskAucRight: updateRandomIndex(aucAds?.deskAucRight),
                deskAucLeft: updateRandomIndex(aucAds?.deskAucLeft),
                mobAucTop: updateRandomIndex(aucAds?.mobAucTop),
                mobAucBottom: updateRandomIndex(aucAds?.mobAucBottom),
            }))
        }, imageChangeInterval)
        return () => clearInterval(interval)
    }, [aucAds])

    const sortShow = [
        {
            value: 3,
            show: 'Current price low to high',
        },
        {
            value: 5,
            show: 'Current price high to low',
        },
        {
            value: 7,
            show: 'Time remaining',
        },
        {
            value: 13,
            show: 'Recently listed',
        },
        {
            value: 14,
            show: 'Lot # low to high',
        },
        {
            value: 15,
            show: 'Lot # high to low',
        },
    ]

    return (
        <div className="auctionsAdsCnt">
            {global?.pluginConfiguration?.advertisement?.enable == 1 ? (
                <div className="siteAds adsAuc mobileView">
                    {isMobile
                        ? aucAds?.mobAucTop[activeAucAdsIndex?.mobAucTop]
                        : aucAds?.deskAucLeft[activeAucAdsIndex?.deskAucLeft]}
                </div>
            ) : null}
            <div
                className="table-responsive lineItemBidTable mt-2 sublots-table mySubmitTable"
                id="sublot"
            >
                {props.showviews ? (
                    <div className="d-flex justify-content-end align-items-center flex-wrap mt-5 mb-3">
                        <div className="auctionsMiscFilter">
                            <div className="gridListToggle">
                                <Button
                                    className={auctionView === 'Grid' ? 'active' : ''}
                                    onClick={() => handleAuctionView('Grid')}
                                >
                                    <span className="material-icons">apps</span>Grid
                                </Button>
                                <Button
                                    className={auctionView === 'List' ? 'active' : ''}
                                    onClick={() => handleAuctionView('List')}
                                >
                                    <span className="material-icons">view_list</span>List
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {global?.storeConfigration?.show_lot_sort?.value == 1 && (
                    <div className="d-flex justify-content-between align-items-center flex-wrap my-3 auctionSearchCnt">
                        <h2 className="auctionListTitle"></h2>
                        <div className="auctionsMiscFilter">
                            <CustomSelect
                                label="Sort By"
                                value={props.subLotSearch.sortBy}
                                size="small"
                                selectType="noBorder"
                                name="auc_type"
                                // shrink={search.orderby !== '' ? true : false}
                                onChange={(event, editor) => {
                                    if (event.target.value != '') {
                                        props.setSubLotSearch({
                                            ...props.subLotSearch,
                                            sortBy: event.target.value,
                                            page: 1,
                                        })
                                    }
                                }}
                            >
                                {sortShow.map((opt, optindex) => (
                                    <option value={opt.value}>{opt.show}</option>
                                ))}
                            </CustomSelect>
                        </div>
                    </div>
                )}

                {auctionView == 'List' ? (
                    <TableView
                        lotData={props.lotData}
                        selectedPostion={props.selectedPostion}
                        auctionTimer={auctionTimer}
                        setSubLotData={props.setSubLotData}
                        audio={props.audio}
                        cardTheme="liquidation"
                        upcomming={props.upcomming}
                        is_registered={props.is_registered}
                        private_auction={props.private_auction}
                        mobileAds={aucAds?.mobAucBottom[activeAucAdsIndex?.mobAucBottom]}
                        isMobile={isMobile}
                        mobileAdsFrequency={
                            global.pluginConfiguration?.advertisement?.enable == 1
                                ? Number(global.pluginConfiguration?.advertisement?.range_key)
                                : 3
                        }
                    />
                ) : (
                    <SearchProductList
                        listview={false}
                        searchResult={props.lotData}
                        className="liquidationBuyCard"
                        cardType={'grid'}
                        setViewProduct={props.setSubLotData}
                    />
                )}
                <PaginationComponent
                    onPageChange={(pageNumber) => {
                        document.getElementById('sublot').scrollIntoView()
                        props.setSubLotSearch({ ...props.subLotSearch, page: pageNumber })
                    }}
                    totalProductCount={props.subLotDataCount}
                    currentPage={props.subLotSearch.page}
                    resultToShow={props.subLotSearch.limit}
                />
                <Bidhistory
                    open={Object.keys(selectedHistory).length !== 0}
                    value={selectedHistory}
                    serverTime={props.serverTimeSocket}
                    handleClose={() => openHistory({})}
                />
            </div>
            <div className="siteAds AdsContainer">
                {global?.pluginConfiguration?.advertisement?.enable == 1 ? (
                    <>
                        {/* {totalAdsCount === 0 ? (
                            <div className="siteAds adsAuc">
                                {isMobile
                                    ? aucAds?.mobAucBottom[activeAucAdsIndex?.mobAucBottom]
                                    : aucAds?.deskAucRight[activeAucAdsIndex?.deskAucRight]}
                            </div>
                        ) : (
                            <>
                                {isMobile ? (
                                    <div className="siteAds adsAuc">
                                        {aucAds?.mobAucBottom[activeAucAdsIndex?.mobAucBottom]}
                                    </div>
                                ) : (
                                    Array.from({ length: totalAdsCount }, (_, i) => (
                                        <div className="siteAds adsAuc">
                                            {aucAds?.deskAucRight[activeAucAdsIndex?.deskAucRight]}
                                        </div>
                                    ))
                                )}
                            </>
                        )} */}
                        <div className="siteAds adsAuc">
                            {isMobile
                                ? aucAds?.mobAucBottom[activeAucAdsIndex?.mobAucBottom]
                                : aucAds?.deskAucRight[activeAucAdsIndex?.deskAucRight]}
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    )
}

export default SubLotsComponent
